import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { sprintf } from 'sprintf-js';

import { getPageDataSelector } from 'common/selectors/page';
import {
  getActiveThemeSelector,
  getCurrencySymbolSelector,
  getDomainSelector,
  getLanguageSelector,
  getLogoSelector
} from 'common/selectors';

import { getLogoPath, setRouteParams } from 'common/utils';

const RichSnippetBreadCrumbs = ({
  dataCategorical,
  activeTheme,
  currentLanguage,
  domain
}) => {
  if (!_.isEmpty(dataCategorical)) {
    const items = _.map(dataCategorical, (item, index) => {
      const { name, urlName } = item;
      return {
        '@type': 'ListItem',
        position: index,
        name: name,
        item: sprintf(
          '%1$s%2$s',
          domain,
          setRouteParams(
            'category',
            { id: urlName },
            currentLanguage,
            activeTheme,
            {}
          )
        )
      };
    });
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: items
          })
        }}
      />
    );
  }
  return null;
};

const RichSnippetBreadCrumbsConnected = connect(() => {
  const getPageData = getPageDataSelector();
  return (state, props) => {
    return {
      dataCategorical: _.get(
        getPageData(state, props),
        'breadcrumbs.categorical',
        null
      )
    };
  };
})(RichSnippetBreadCrumbs);

const RichSnippetSearch = ({
  domain,
  currentLanguage,
  activeTheme
}) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'WebSite',
          url: domain,
          potentialAction: {
            '@type': "SearchAction",
            target: decodeURI(sprintf(
              '%1$s%2$s',
              domain,
              setRouteParams(
                'search',
                { searchPhrase: '{search_term_string}' },
                currentLanguage,
                activeTheme,
                {}
              )
            )),
            'query-input': "required name=search_term_string"
          }
        })
      }}
    />
  );
};

const RichSnippetSearchConnected = connect()(RichSnippetSearch);

const RichSnippetProduct = ({
  product,
  currencySymbol,
  domain,
  currentLanguage,
  activeTheme
}) => {
  if (!_.isEmpty(product)) {
    const {
      name,
      shortProductDescription,
      brand,
      finalPrice,
      finalPriceData,
      urlTitle,
      photo,
      sku = '',
      ean = '',
      availability
    } = product;
    const imageWidth = 400;
    const imageHeight = 250;
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name,
            image:
              photo && photo.path
                ? [
                    sprintf(
                      '%1$s/%2$s/%3$s',
                      _.trimEnd(domain, '/'),
                      _.trimStart(photo.path, '/'),
                      `${imageWidth}x${imageHeight}.jpg`
                    )
                  ]
                : '',
            description: _.get(shortProductDescription, 'description', ''),
            sku: sku,
            mpn: ean,
            brand: {
              '@type': 'Thing',
              name: _.get(brand, 'name', '')
            },
            offers: {
              '@type': 'Offer',
              url: sprintf(
                '%1$s%2$s',
                domain,
                setRouteParams(
                  'product',
                  { id: urlTitle },
                  currentLanguage,
                  activeTheme,
                  {}
                )
              ),
              priceCurrency: currencySymbol,
              price: _.get(finalPriceData, 'price', finalPrice),
              availability: availability
                ? 'https://schema.org/InStock'
                : 'https://schema.org/OutOfStock',
              seller: {
                '@type': 'Organization',
                name: domain
              }
            }
          })
        }}
      />
    );
  }
  return null;
};

const RichSnippetProductConnected = connect(() => {
  const getPageData = getPageDataSelector();
  const getCurrencySymbol = getCurrencySymbolSelector();
  return (state, props) => {
    return {
      product: _.get(getPageData(state, props), 'product'),
      currencySymbol: getCurrencySymbol(state, props)
    };
  };
})(RichSnippetProduct);

const RichSnippetDefault = ({ domain, logo, socials }) => {
  let sameAs;
  if (!_.isEmpty(socials) && _.isArray(socials)) {
    sameAs = [].concat(socials);
  }
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Corporation',
          name: domain,
          url: domain,
          logo: sprintf(
            '%1$s/%2$s',
            _.trimEnd(domain, '/'),
            _.trimStart(logo, '/')
          ),
          sameAs
        })
      }}
    />
  );
};

const RichSnippetDefaultConnected = connect(() => {
  const getLogo = getLogoSelector();
  return (state, props) => {
    return {
      logo: getLogoPath(getLogo(state, props), '200x47.png')
    };
  };
})(RichSnippetDefault);

const RichSnippet = ({
  type,
  activeTheme,
  currentLanguage,
  domain,
  socials = []
}) => {
  if (type === 'breadcrumbs') {
    return (
      <RichSnippetBreadCrumbsConnected
        activeTheme={activeTheme}
        currentLanguage={currentLanguage}
        domain={domain}
      />
    );
  } else if (type === 'product') {
    return (
      <RichSnippetProductConnected
        activeTheme={activeTheme}
        currentLanguage={currentLanguage}
        domain={domain}
      />
    );
  } else if (type === 'search') {
    return (
      <RichSnippetSearchConnected
        activeTheme={activeTheme}
        currentLanguage={currentLanguage}
        domain={domain}
      />
    );
  } else {    
    return <RichSnippetDefaultConnected domain={domain} socials={socials} />;
  }
};

const RichSnippetConnected = connect(() => {
  const getActiveTheme = getActiveThemeSelector();
  const getLanguage = getLanguageSelector();
  const getDomain = getDomainSelector();
  return (state, props) => {
    return {
      activeTheme: getActiveTheme(state, props),
      currentLanguage: getLanguage(state, props),
      domain: getDomain(state, props)
    };
  };
})(RichSnippet);

export default RichSnippetConnected;
