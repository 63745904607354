import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import Modal from 'common/components/_partials/modal/mobilonline/index';

import {
  getPageDataSelector
} from 'common/selectors/page';

class QuickSearchInfoModal extends PureComponent {
  render() {
    const {
      data,
      t
    } = this.props;
    return (
      <Modal modalId="quickSearchModal" title={_.get(data, 'title', null)}>
        <div dangerouslySetInnerHTML={{ __html: _.get(data, 'content', null) }} />
      </Modal>
    );
  }
}


const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  const mapStateToProps = (state, props) => {
    const pageData = getPageData(state, props);
    return {
      data: _.get(pageData, 'howToFindPhoneModelPage', null)
    };
  };
  return mapStateToProps;
};

const QuickSearchInfoModalConnected = connect(makeMapStateToProps)(QuickSearchInfoModal);

export default withTranslation()(QuickSearchInfoModalConnected);
