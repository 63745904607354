import React, {Fragment} from 'react';
import _ from 'lodash';

import SS from 'common/components/product/_styles';

export default ({ data }) => {
  
  return (
    <Fragment>
      {_.map(data, info => {
        return (
          <SS.Block key={'additional-info-' + info.id}>
            <h3>{info.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: info.text }} />
          </SS.Block>
        );
      })}
    </Fragment>
  );
};