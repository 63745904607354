import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';

import { capfl } from 'common/utils';
import ProductCarousel from 'common/components/_base/productCarousel/_default/index';

const S = {};

S.Title = styled.h3`
  margin: 0 0 25px 0;
  color: ${({ theme }) => _.get(theme, 'colors.text')};
  text-align: center;
`;

S.Wrapper = styled.section`
  margin-top: 20px;
  padding-top: 20px; 
`;

class RelatedProducts extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { t, theme, data } = this.props;
    const settings = _.get(theme, 'relatedProductsCarouselSettings', {});
    if (!_.isEmpty(data) && !_.isEmpty(settings)) {
      return (
        <S.Wrapper>
          <S.Title>{capfl(t('related products'))}</S.Title>
          <ProductCarousel useSmallCard={true} settings={settings} data={data} type="related-products" />
        </S.Wrapper>
      )
    }
    return null;
  }
}

export default withTranslation()(
  withTheme(RelatedProducts)
);