import styled from 'styled-components';

import { withCssUnit } from 'common/components/styleguide/utils/index';

import Carousel from 'common/components/styleguide/Carousel';

export const StyledCarousel = styled(Carousel)`
  margin-bottom: 20px;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-bottom: 0;
  }
`;

export const StyledCarouselItem = styled.div`
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
`;
