import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Row, Column } from 'common/components/styleguide/grid';
import { getHomepageArticlesSelector } from 'common/selectors/page';

import { withCssUnit } from 'common/components/styleguide/utils/index';

import RouteLink from 'common/components/_base/RouteLink';
import ButtonRouteLink from 'common/components/_base/ButtonRouteLink';
import Image from 'common/components/_base/Image';
import Carousel from 'common/components/styleguide/Carousel';
import Icon from 'common/components/styleguide/Icon';

const S = {};

S.CarouselArrow = styled.button`
  &.slick-arrow {
    position: absolute;
    z-index: 1;
    line-height: 1;
    cursor: pointer;
    right: -40px;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding: 0;
    margin-top: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.marginTop', '-20px')};
    background: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.background', '#fff')};
    border: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.border', '1px solid #eee')};
    color: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.color', '#222')};
    font-size: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.fontSize', '16px')};
    width: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.width', '40px')};
    height: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.height', '40px')};
    border-radius: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.borderRadius')};
    &:hover {
      color: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.hover.color', '#222')};
      border: ${({ theme }) =>
        _.get(theme, 'productCarrousels.arrows.hover.border', '1px solid #aaa')};
      background: ${({ theme }) =>
        _.get(theme, 'productCarrousels.arrows.hover.background', '#fff')};
      @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
        color: ${({ theme }) => _.get(theme, 'productCarrousels.arrows.md.hover.color', '#222')};
        border: ${({ theme }) =>
          _.get(theme, 'productCarrousels.arrows.md.hover.border', '1px solid #aaa')};
        background: ${({ theme }) =>
          _.get(theme, 'productCarrousels.arrows.md.hover.background', '#fff')};
      }
    }
    &:focus {
      outline: none;
    }
    &:before {
      display: none;
    }
    .icon {
      display: block;
    }
  }
`;

S.CarouselArrowPrev = styled(S.CarouselArrow)`
  &.slick-arrow {
    left: -40px;
    right: auto;
  }
`;

const NextArrow = props => {
  return (
    <S.CarouselArrow {...props}>
      <Icon className="nc-ic_keyboard_arrow_right_48px" />
    </S.CarouselArrow>
  );
};

const PrevArrow = props => {
  return (
    <S.CarouselArrowPrev {...props}>
      <Icon className="nc-ic_keyboard_arrow_left_48px " />
    </S.CarouselArrowPrev>
  );
};


S.Article = styled.div`
  background: ${({ theme }) => _.get(theme, 'colors.white')};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin: 0 10px;
  }
`;

S.Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    flex-direction: column;
  }
`;

S.ImageLink = styled(RouteLink)`
  display: block;
  overflow: hidden;
`;

S.Image = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
`;

S.Bottom = styled.div`
  padding: 20px;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    padding: 30px 20px 20px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (min-width: 900px) {
    min-height: 0;
  }
`;

S.Title = styled(RouteLink)`
  margin: 0 0 15px 0;
  font-weight: 600;
  font-size: 0.9em;
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
  text-decoration: none;
  min-height: 36px;
  display: block;
  text-decoration: none;
`;

S.Text = styled.div`
  font-size: 0.8em;
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
  margin-bottom: 20px;
`;

S.Button = styled(ButtonRouteLink)`
  width: 150px;
`;

S.CarouselWrapper = styled.div`
  position: relative;
  padding: 0 40px;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    padding: 0;
  }
`;

class Articles extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { t, theme, articles } = this.props;
    const settings = _.get(theme, 'articlesCarouselSettings', {});
    if (!_.isEmpty(articles) && !_.isEmpty(settings)) {
      return (
        <Row>
          <Column size={{ xs: 12 }}>
            <S.CarouselWrapper>
              <Carousel settings={settings} CustomNextArrow={NextArrow} CustomPrevArrow={PrevArrow}>
                {_.map(articles, article => (
                  <div key={'slider-blog-articles' + article.id}>
                    <S.Article>
                      <S.Inner>
                        <S.ImageLink
                          route="blog-detail"
                          params={{ id: article.urlTitle }}
                          rel="nofollow"
                        >
                          <S.Image {...article.photo} size="800x300.jpg" />
                        </S.ImageLink>
                        <S.Bottom>
                          <S.Title route="blog-detail" params={{ id: article.urlTitle }}>
                            {article.title}
                          </S.Title>
                          <S.Text>
                            <div dangerouslySetInnerHTML={{ __html: article.perex }} />
                          </S.Text>
                          <S.Button
                            route="blog-detail"
                            params={{ id: article.urlTitle }}
                            buttonType="secondary"
                            outline
                            rel="nofollow"
                          >
                            {t('read more')}
                          </S.Button>
                        </S.Bottom>
                      </S.Inner>
                    </S.Article>
                  </div>
                ))}
              </Carousel>
            </S.CarouselWrapper>
          </Column>
        </Row>
      );
    }
    return null;
  }
}

const makeMapStateToProps = () => {
  const getArticles = getHomepageArticlesSelector();
  const mapStateToProps = (state, props) => {
    return { articles: getArticles(state, props) };
  };
  return mapStateToProps;
};

const ArticlesConnected = connect(makeMapStateToProps)(Articles);

export default withTranslation()(withTheme(ArticlesConnected));
