import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import Availability from 'common/components/_partials/availability/_default/index';
import RouteLink from 'common/components/_base/RouteLink';
import Price from 'common/components/_base/Price';
import Image from 'common/components/_base/Image';

const S = {};

S.Item = styled.section`
  display: flex;
  background: ${({ theme }) => (theme.colors ? theme.colors.white : null)};
  padding: 5px;
  align-items: center;
  margin-bottom: 5px;
  border: ${({ theme }) => _.get(theme, 'border.default.width')} ${({ theme }) => _.get(theme, 'border.default.style')} ${({ theme }) => _.get(theme, 'border.default.color')};
`;

S.ImageLink = styled(({ children, urlTitle, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  display: block;
  text-decoration: none;
  min-width: 60px;
  width: 60px;
`;

S.Info = styled.section`
  min-width: 0;
  padding: 5px;
  padding-left: 10px;
  font-size: 0.8em;
`;

S.Name = styled(({ children, urlTitle, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  color: ${({ theme }) => _.get(theme, 'productCard.name.color', '#999')};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => _.get(theme, 'productCard.name.color', '#999')};
    text-decoration: underline;
  }
`;

S.Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

class ProductCardSmall extends PureComponent {
  render() {
    const { data } = this.props;
    if (data) {
      const { 
        photo, 
        name, 
        availability, 
        storeStatusAvailable, 
        storeStatusUnavailable, 
        urlTitle, 
        finalPriceData = {}, 
        finalPrice,
        finalPriceWithoutDPH,
        measureUnit } = data;
      return (
        <S.Item>
          <S.ImageLink urlTitle={urlTitle}>
            <Image
              {...photo}
              size="60x60.png"
              alt={photo ? photo.title : name}
            />
          </S.ImageLink>
          <S.Info>
            <S.Name urlTitle={urlTitle}>{name}</S.Name>
            <S.Wrapper>
              <Price
                finalPrice={!_.isEmpty(finalPriceData) ? finalPriceData.priceFormatted : finalPrice}
                finalPriceWithoutDPH={!_.isEmpty(finalPriceData) ? finalPriceData.priceWithoutVatFormatted : finalPriceWithoutDPH}
                measureUnit={measureUnit}
                showDiscount={false}
                showPriceOld={false}
                useFormatPrice={!_.isEmpty(finalPriceData) ? false : true}
                useFormatDiscount={false}
                useCurrencySymbol={!_.isEmpty(finalPriceData) ? false : true}
              />
              <Availability availability={availability} text={availability ? storeStatusAvailable : storeStatusUnavailable} />
            </S.Wrapper>
          </S.Info>
        </S.Item>
      );
    }
    return null;
  }
}

export default withTranslation()(ProductCardSmall);
