import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';

import { Container, Row, Column } from 'common/components/styleguide/grid';

import Link from 'common/components/styleguide/Link';
import List from 'common/components/styleguide/List';
import TextIcon from 'common/components/styleguide/TextIcon';
import { gotoUrl } from 'common/utils/index';
import { withCssUnit } from 'common/components/styleguide/utils';

import { fireGTMEvent } from 'common/utils/gtm';
import { NEWSLETTER_SUBSCRIBE } from 'common/constants/GTMConstants';

const StyledRow = styled(Row)`
  justify-content: center;
`;

const StyledSectionNewsletter = styled.section`
  padding: 60px 0;
  background: ${({ theme }) => _.get(theme, 'newsletter.background', null)};
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledNewsletterTitle = styled.h6`
  font-size: ${({ theme }) => _.get(theme, 'newsletter.title.fontSize', null)};
  color: ${({ theme }) => _.get(theme, 'newsletter.title.color', null)};
  margin: 0 0 20px 0;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: capitalize;
`;

const StyledNewsletterListItem = styled.li`
  margin-bottom: 15px;
  color: ${({ theme }) => _.get(theme, 'newsletter.text.color', null)};
  font-size: ${({ theme }) => _.get(theme, 'newsletter.text.fontSize', null)};
  &:last-child {
    margin-bottom: 0;
  }
  .icon {
    color: ${({ theme }) => _.get(theme, 'newsletter.icon.color', null)};
    font-size: ${({ theme }) => _.get(theme, 'newsletter.icon.fontSize', null)};
    margin-right: 15px;
  }
`;

const StyledNewsletterLink = styled(Link)`
  && {
    display: inline-block;
    background: ${({ theme }) =>
      _.get(theme, 'newsletter.linkButton.background', null)};
    color: ${({ theme }) => _.get(theme, 'newsletter.linkButton.color', null)};
    padding: 15px 35px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 500;
    margin-top: 35px;
    text-align: center;
    &:hover {
      background: ${({ theme }) =>
        _.get(theme, 'newsletter.linkButton..hover.background', null)};
      color: ${({ theme }) =>
        _.get(theme, 'newsletter.linkButton.hover.color', null)};
    }
    @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
      text-align: left;
      margin-top: 25px;
    }
  }
`;

class Newsletter extends PureComponent {
  onLinkClick = e => {
    e.preventDefault();
    fireGTMEvent(NEWSLETTER_SUBSCRIBE);
    gotoUrl(e.target.href, e.target.target);
  };
  render() {
    const { t } = this.props;
    return (
      <StyledSectionNewsletter>
        <Container>
          <StyledRow>
            <Column size={{ xs: 12, md: 10 }}>
              <Row>
                <Column size={{ xs: 12, md: 7 }}>
                  <StyledNewsletterTitle>
                    {t('newsletter')}
                  </StyledNewsletterTitle>
                  <List reset>
                    <StyledNewsletterListItem>
                      <TextIcon iconClassName="nc-check-simple-1">
                        {t(
                          'be a part of closed society actions only for newsletter subscribers'
                        )}
                      </TextIcon>
                    </StyledNewsletterListItem>
                    <StyledNewsletterListItem>
                      <TextIcon iconClassName="nc-check-simple-1">
                        {t('be informed about news as the first')}
                      </TextIcon>
                    </StyledNewsletterListItem>
                  </List>
                </Column>
                <Column size={{ xs: 12, md: 4 }}>
                  <StyledNewsletterLink
                    href="https://eepurl.com/du5xdz"
                    target="_blank"
                    onClick={this.onLinkClick}
                  >
                    {t('subscribe')}
                  </StyledNewsletterLink>
                </Column>
              </Row>
            </Column>
          </StyledRow>
        </Container>
      </StyledSectionNewsletter>
    );
  }
}

export default withTranslation()(Newsletter);
