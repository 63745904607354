import styled from 'styled-components';
import _ from 'lodash';

import { withCssUnit } from 'common/components/styleguide/utils';

const SS = {};

SS.Title = styled.h1`
  margin: 5px 0;
  color: ${({ theme }) => _.get(theme, 'colors.text')}; 
  margin-bottom: 10px;
  font-weight: normal;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin: 25px 0;
  }
`;

SS.Subtitle = styled.h2`
  margin: 5px 0;
  color: ${({ theme }) => _.get(theme, 'colors.lgray')};
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 1.1em;
  line-height: 1.5em;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    font-size: 1em;
  }  
`;

SS.Brand = styled.span`
  color: ${({ theme }) => _.get(theme, 'colors.lgray')}; 
  font-weight: 600;
`;

SS.Block = styled.section`
  margin: 15px 0;
`;

export default SS;
