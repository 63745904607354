import React, { Fragment } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import SS from 'common/components/product/_styles';
const S = {};
S.Label = styled.span`
  display: inline-block;
`;

S.Value = styled.span`
  display: inline-block;
  margin-left: 5px;
`;

S.Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
`;

S.Tr = styled.tr`
  border: none;
  &:nth-child(2n) {
    background: ${({ theme }) => _.get(theme, 'page.backgroundSecondary')};
  }
`;

S.Td = styled.td`
  width: 50%;
  border: none;
  padding: 8px 4px;
`;

S.GroupTitle = styled.h4`
`;

export default ({ data }) => {
  const groups = _.groupBy(data, item => (item.group ? item.group : 'no-group'));
  return (
    <Fragment>
      {_.map(groups, (group, key) => {
        const groupedParameters = _.groupBy(group, item => (item.name ? item.name : ' '));
        return (
          <SS.Block key={'parameters-group-' + key}>
            {key !== 'no-group' ? <S.GroupTitle>{key}</S.GroupTitle> : ''}
            <S.Table>
              <tbody>
                {_.map(groupedParameters, (items, index) => {
                  return (
                    <S.Tr key={'parameter-' + index}>
                      <S.Td><S.Label>{index.replace(/\u00a0/g, " ")}:</S.Label></S.Td>
                      <S.Td>
                        <S.Value>
                          {_.join(_.map(items, item => _.get(item, 'value', '')), ', ')}
                        </S.Value>
                      </S.Td>
                    </S.Tr>
                  );
                })}
              </tbody>
            </S.Table>
          </SS.Block>
        );
      })}
    </Fragment>
  );
};
