import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';

import { Row, Column } from 'common/components/styleguide/grid';
import { capfl } from 'common/utils/index';

import SS from 'common/components/product/_styles';
const S = {};

S.MainRating = styled(SS.Block)`
  border: ${({ theme }) => _.get(theme, 'border.default.width')} ${({ theme }) => _.get(theme, 'border.default.style')} ${({ theme }) => _.get(theme, 'border.default.color')};
  padding: 20px;
  background: ${({ theme }) => _.get(theme, 'page.backgroundSecondary')}; 
`;

S.HeadlineMain = styled.h4`
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
  margin-top: 0;
`;

S.Headline = styled.h5`
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
`;

S.Rating = styled.span`
  font-size: 1em;
`;

S.NumberCriteria = styled.span`
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
  font-size: 2em;
  font-weight: bolder:
`;

S.NumberScale = styled.span`
  color: ${({ theme }) => _.get(theme, 'colors.text')};
`;

const Ratings = ({ t, data }) => {
  const {
    criteria = null, 
    totalRating = null 
  } = data;
  return (
    <Fragment>
      <Row>
        <Column size={{ xs: 12, md: 3 }}>
          <S.MainRating>
            <S.HeadlineMain>
              {capfl(t('our overall rating'))}
            </S.HeadlineMain>
            <S.Rating>
              <S.NumberCriteria>{_.get(totalRating, 'rating', 0)}</S.NumberCriteria> 
              <S.NumberScale> / {_.get(totalRating, 'scale', 0)}</S.NumberScale>
            </S.Rating>  
          </S.MainRating>
        </Column>
      </Row>
      <Row>
        {_.map(criteria, (item, key) => {
          return (
            <Column key={'criteria-item-' + key} size={{ xs: 6, md: 3 }}>
              <SS.Block>
                <S.Headline>{_.get(item, 'name')}</S.Headline>
                <S.Rating>
                  <S.NumberCriteria>{_.get(item, 'rating', 0)}</S.NumberCriteria> 
                  <S.NumberScale> / {_.get(item, 'scale', 0)}</S.NumberScale>
                </S.Rating>              
                <p>{_.get(item, 'description')}</p>
              </SS.Block>
            </Column>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default withTranslation()(Ratings);