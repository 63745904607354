import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

const withDomain = WithDomainComponent => {
  class HOC extends Component {
    render() {
      const { domain } = this.props;
      if (domain) {
        return <WithDomainComponent {...this.props} {...this.state} />;
      }
      return null;
    }
  }
  const mapStateToProps = state => {
    return { domain: _.get(state, 'app.webInstance.domain', null) };
  };
  return connect(mapStateToProps)(HOC);
};

export default withDomain;
