import styled from 'styled-components';
import _ from 'lodash';

const FormLabel = styled.label`
  color: ${({ theme, error, isDisabled }) => {
    let color = _.get(theme, 'label.color', null);
    if (error) {
      color = _.get(theme, 'colors.danger', null);
    }
    if (isDisabled) {
      color = _.get(theme, 'colors.disabled', '#ccc');
    }
    return color;
  }};
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: ${({ isInline }) => (isInline ? 0 : '5px')};
  margin-right: ${({ isInline }) => (isInline ? '10px' : 0)};
  &::first-letter {
    text-transform: uppercase;
  }
`;

export default FormLabel;
