import React, { Component } from 'react';
import { connect } from 'react-redux';

const DEFAULT_THEME_COMPONENTS_KEY = '_default';

const themeComponent = (components = {}) => {
  class HOC extends Component {
    render() {
      const { activeTheme, children, ...props } = this.props;
      if (activeTheme && components[activeTheme]) {
        return React.createElement(components[activeTheme], props, children);
      }
      if (components[DEFAULT_THEME_COMPONENTS_KEY]) {
        return React.createElement(components[DEFAULT_THEME_COMPONENTS_KEY], props, children);
      }
      return null;
    }
  }
  const mapStateToProps = state => {
    const { app } = state;
    return { activeTheme: app.activeTheme };
  };
  return connect(mapStateToProps)(HOC);
};

export default themeComponent;
