import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import loadable from '@loadable/component';

import { capfl } from 'common/utils/index';
// import Image from 'common/components/styleguide/Image';
import { withCssUnit } from 'common/components/styleguide/utils/index';
import { getPageDataSelector } from 'common/selectors/page';

import storeSrc from 'assets/images/mobilonline/icon_marker.png';
import badgeSrc from 'assets/images/mobilonline/icon_badge.png';
import markerSrc from 'assets/images/mobilonline/icon_store.png';


const Image = loadable(
  () =>
    import('common/components/styleguide/Image'),
  {ssr: false}
);

const StyledPicks = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    flex-direction: row;
  }
`;

const StyledPick = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 20px;
  background: #fff;
  border-bottom: 2px solid #f6f6f6;
  &:last-child {
    border-bottom: 0;
  }
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    flex: 1 0 0;
    border-bottom: 0;
    border-right: 1px solid #f6f6f6;
    border-left: 1px solid #f6f6f6;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
`;

const StyledInner = styled.section`
  display: flex;
  align-items: center;
`;

const StyledText = styled.section`
  margin-left: 20px;
  max-width: 180px;
  line-height: 22px;
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
  & p {
    padding: 0;
    margin: 0;
  }
`;

const StyledImageWrapper = styled.div`
  width: 40px;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`;


const picks = [
  {
    id: 'badge',
    image: { src: badgeSrc, alt: 'badge' },
    hash: 'homepage_marketing_text_01',
    text: '17 years of professional experiences'
  },
  {
    id: 'store',
    image: { src: storeSrc, alt: 'store' },
    hash: 'homepage_marketing_text_02',
    text: 'more than 40 stores in slovakia'
  },
  {
    id: 'marker',
    image: { src: markerSrc, alt: 'marker' },
    hash: 'homepage_marketing_text_03',
    text: 'sold more than 55800 products'
  }
];

class Picks extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { t, staticBlocks } = this.props;
    return (
      <StyledPicks>
        {_.map(picks, pick => {
          const { id, image, text, hash } = pick;
          return (
            <StyledPick key={'pick-' + id}>
              <StyledInner>
                <StyledImageWrapper>
                  <StyledImage src={image.src} alt={image.alt} staticImage={false} />
                </StyledImageWrapper>
                <StyledText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: _.get(_.find(staticBlocks, ['hash', hash]), 'content', capfl(t(text)))
                    }}
                  ></div>
                </StyledText>
              </StyledInner>
            </StyledPick>
          );
        })}
      </StyledPicks>
    );
  }
}

const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  return (state, props) => {
    return {
      staticBlocks: _.get(getPageData(state, props), 'staticBlocks')
    };
  };
};

export default withTranslation()(connect(
  makeMapStateToProps
)(Picks));
