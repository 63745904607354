import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';
import { withSizes } from 'react-sizes';

import { Column, Container, Row } from 'common/components/styleguide/grid';
import Icon from 'common/components/styleguide/Icon';
import Link from 'common/components/styleguide/Link';
import List from 'common/components/styleguide/List';
import Image from 'common/components/_base/Image';
import RouteLink from 'common/components/_base/RouteLink';
import QuickSearch from 'common/components/home/quickSearch';
import { withCssUnit } from 'common/components/styleguide/utils/index';
import { getBannersSelector } from 'common/selectors/page';
import { getMenuTopSelector } from 'common/selectors/menu';
import { StyledCarousel, StyledCarouselItem } from './_styles';
import {
  BANNERS_TYPE_CUSTOMBANNER_1,
  BANNERS_TYPE_HOMEPAGE
} from 'common/constants/SiteConstants';
import { getMapSizesToPropsCustom } from 'common/utils';

const StyledCustomBanner1 = styled(({ component, children, ...props }) => {
  const Component = component === 'div' && props.href ? 'a' : component;
  return <Component {...props}>{children}</Component>;
})`
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    width: 100%;
  }
`;

const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
`;

const CustomBanner1 = ({ data, className = '', imageSize = '330x203.png' }) => {
  if (data) {
    const { url, photo, component = 'div' } = data;
    return (
      <StyledCustomBanner1
        component={component}
        href={url}
        className={className}
      >
        <StyledImage
          {...photo}
          size={imageSize}
          lazyloadPlaceholder={
            <Image {...photo} lazyload={false} size="330x203.png" />
          }
          alt="banner"
        />
      </StyledCustomBanner1>
    );
  }
  return null;
};

const StyledCustomBanner1Top = styled(CustomBanner1)`
  margin-bottom: 0;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-bottom: 20px;
  }
`;

const CustomBanners1 = ({ data }) => {
  if (_.isArray(data) && data.length >= 2) {
    return (
      <Row>
        <Column size={{ xs: 6, md: 12 }}>
          <StyledCustomBanner1Top data={data[0]} />
        </Column>
        <Column size={{ xs: 6, md: 12 }}>
          {/*<CustomBanner1 data={data[1]} />*/}
          <QuickSearch />
        </Column>
      </Row>
    );
  }
  return null;
};

const StyledCustomBanners1Column = styled(Column)`
  display: none;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
  }
`;

const StyledSmallNavWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  background: ${({ theme }) => _.get(theme, 'colors.white', '#fff')};
`;

const TopBanners = ({ carouselBanners = [], customBanners1 = [] }) => {
  let carouselBannersColumnSize = { xs: 12 };
  if (_.isArray(customBanners1) && customBanners1.length >= 2) {
    carouselBannersColumnSize = { xs: 12, md: 9 };
  }
  return (
    <Container>
      <Row>
        {_.isArray(carouselBanners) && carouselBanners.length > 0 ? (
          <Column size={carouselBannersColumnSize}>
            <MainCarousel items={carouselBanners} />
          </Column>
        ) : (
          ''
        )}
        <StyledCustomBanners1Column size={{ xs: 12, md: 3 }}>
          <CustomBanners1 data={customBanners1} />
        </StyledCustomBanners1Column>
      </Row>
    </Container>
  );
};

const StyledBottomBannersWrapper = styled.div`
  padding: 20px 0;
  background: #f7f7f8;
  overflow-x: hidden;
`;

class BottomBanners extends PureComponent {
  render() {
    const { customBanners1 = [], isSmallScreen = false } = this.props;
    if (
      _.isArray(customBanners1) &&
      customBanners1.length > 0 &&
      isSmallScreen
    ) {
      return (
        <StyledBottomBannersWrapper>
          <Container>
            <Row>
              <Column size={{ xs: 12 }}>
                <QuickSearch/>
              </Column>
            </Row>
            <Row>
              <Column size={{ xs: 12 }}>
                <MobileCarousel items={customBanners1} />
              </Column>
            </Row>
          </Container>
        </StyledBottomBannersWrapper>
      );
    }
    return null;
  }
}

const BottomBannersResponsive = withTheme(
  withSizes(getMapSizesToPropsCustom())(BottomBanners)
);

const MainCarousel = ({ items = [] }) => {
  return (
    <StyledCarousel settings={{ autoplay: true, lazyLoad: true }}>
      {items.map((item, i) => {
        const itemId = _.get(item, 'id', i);
        return (
          <StyledCarouselItem key={'home-main-carousel-item-' + itemId}>
            {item.url ? (
              <Link href={item.url}>
                <Image
                  {...item.photo}
                  srcSet={{
                    '460px': '460x190.jpg',
                    '780px': '1030x426.jpg'
                  }}
                  size="460x190.jpg"
                  sizes="100vw"
                  alt="banner"
                  lazyload={false}
                />
              </Link>
            ) : (
              <Image
                {...item.photo}
                srcSet={{
                  '460px': '460x190.jpg',
                  '780px': '1030x426.jpg'
                }}
                size="460x190.jpg"
                sizes="100vw"
                alt="banner"
                lazyload={false}
              />
            )}
          </StyledCarouselItem>
        );
      })}
    </StyledCarousel>
  );
};

const MobileCarousel = ({ items = [] }) => {
  return (
    <StyledCarousel>
      {items.map((item, i) => {
        const itemId = _.get(item, 'id', i);
        return (
          <StyledCarouselItem key={'home-bottom-slider-item-' + itemId}>
            {item.url ? (
              <Link href={item.url}>
                <Image
                  {...item.photo}
                  size="460x260.jpg"
                  sizes="100vw"
                  alt="banner"
                  lazyloadPlaceholder={
                    <Image {...item.photo} lazyload={false} size="85x55.jpg" />
                  }
                />
              </Link>
            ) : (
              <Image
                {...item.photo}
                size="460x260.jpg"
                sizes="100vw"
                alt="banner"
                lazyloadPlaceholder={
                  <Image {...item.photo} lazyload={false} size="85x55.jpg" />
                }
              />
            )}
          </StyledCarouselItem>
        );
      })}
    </StyledCarousel>
  );
};

const StyledLink = styled(RouteLink)`
  display: flex;
  text-decoration: none;
  padding: 10px 0;
  justify-content: space-between;
  font-weight: 600;
`;

const StyledListItem = styled.li`
  border-bottom: 2px solid ${({ theme }) => _.get(theme, 'colors.offwhite')};
  &:last-child {
    border-bottom: none;
  }
`;

class SmallNav extends PureComponent {
  render() {
    const { data, isSmallScreen = false } = this.props;
    if (!_.isEmpty(data) && isSmallScreen) {
      return (
        <StyledSmallNavWrapper>
          <nav>
            <List reset>
              {_.map(data, item => {
                return (
                  <StyledListItem key={'smallnav-item-' + item.id}>
                    <StyledLink route="category" params={{ id: item.urlName }}>
                      {item.name}
                      <Icon className="nc-ic_keyboard_arrow_right_24px" />
                    </StyledLink>
                  </StyledListItem>
                );
              })}
            </List>
          </nav>
        </StyledSmallNavWrapper>
      );
    }
    return null;
  }
}

const SmallNavResponsive = withTheme(
  withSizes(getMapSizesToPropsCustom())(SmallNav)
);

const StyledBannersWrapper = styled.section`
  padding: 20px 0;
  background: #f7f7f8;
  overflow-x: hidden;
`;

class HomeBanners extends PureComponent {
  render() {
    const { banners, menu } = this.props;
    if (!_.isEmpty(banners)) {
      const groupedBanners = _.groupBy(banners, 'type');
      return (
        <Fragment>
          <StyledBannersWrapper>
            <TopBanners
              carouselBanners={groupedBanners[BANNERS_TYPE_HOMEPAGE]}
              customBanners1={groupedBanners[BANNERS_TYPE_CUSTOMBANNER_1]}
            />
          </StyledBannersWrapper>
          <Fragment>
            <SmallNavResponsive data={menu} />
            <BottomBannersResponsive
              customBanners1={groupedBanners[BANNERS_TYPE_CUSTOMBANNER_1]}
            />
          </Fragment>
        </Fragment>
      );
    }
    return null;
  }
}

const makeMapStateToProps = () => {
  const getBanners = getBannersSelector();
  const getMenu = getMenuTopSelector();
  return (state, props) => {
    return { banners: getBanners(state, props), menu: getMenu(state, props) };
  };
};

const HomeBannersConnected = connect(makeMapStateToProps)(HomeBanners);

export default HomeBannersConnected;
