import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import Slider from 'react-slick';
import loadable from '@loadable/component';

const Icon = loadable(() => import('common/components/styleguide/Icon'));

const S = {};

S.CarouselWrapper = styled.div`
  position: relative;
`;

S.CarouselArrowWrapper = styled.div`
  &.slick-arrow {
    cursor: pointer;
    position: absolute;
    ${({ theme, arrowSize }) =>
      _.get(theme, ['carousels', 'arrows', arrowSize, 'width'])
        ? `width: ${_.get(theme, ['carousels', 'arrows', arrowSize, 'width'], 'auto')};`
        : ''}
    ${({ theme, arrowSize }) =>
      _.get(theme, ['carousels', 'arrows', arrowSize, 'height'])
        ? `height: ${_.get(theme, ['carousels', 'arrows', arrowSize, 'height'], 'auto')};`
        : ''}
    ${({ theme, arrowSize }) =>
      _.get(theme, 'carousels.arrows.right')
      ? `right: ${_.get(theme, 'carousels.arrows.right')};`
      : _.get(theme, ['carousels', 'arrows', arrowSize, 'width'])
          ? `right: -${_.parseInt(_.get(theme, ['carousels', 'arrows', arrowSize, 'width'])) / 2}px;`
          : 'right: 0;'
      }
    ${({ theme, arrowSize }) =>
      _.get(theme, ['carousels', 'arrows', arrowSize, 'height'])
        ? `margin-top: -${_.parseInt(_.get(theme, ['carousels', 'arrows', arrowSize, 'height'])) / 2}px;`
        : ''}
    top: 50%;
    z-index: 1;
  }
`;

S.CarouselArrowWrapperPrev = styled.div`
  &.slick-arrow {
    cursor: pointer;
    position: absolute;
    ${({ theme, arrowSize }) =>
      _.get(theme, ['carousels', 'arrows', arrowSize, 'width'])
        ? `width: ${_.get(theme, ['carousels', 'arrows', arrowSize, 'width'], 'auto')};`
        : ''}
    ${({ theme, arrowSize }) =>
      _.get(theme, ['carousels', 'arrows', arrowSize, 'height'])
        ? `height: ${_.get(theme, ['carousels', 'arrows', arrowSize, 'height'], 'auto')};`
        : ''}
    ${({ theme, arrowSize }) =>
      _.get(theme, 'carousels.arrows.right')
      ? `left: ${_.get(theme, 'carousels.arrows.left')};`
      : _.get(theme, ['carousels', 'arrows', arrowSize, 'width'])
          ? `left: -${_.parseInt(_.get(theme, ['carousels', 'arrows', arrowSize, 'width'])) / 2}px;`
          : 'left: 0;'
      }
    ${({ theme, arrowSize }) =>
      _.get(theme, ['carousels', 'arrows', arrowSize, 'height'])
        ? `margin-top: -${_.parseInt(_.get(theme, ['carousels', 'arrows', arrowSize, 'height'])) / 2}px;`
        : ''}
    top: 50%;
    z-index: 1
  }
`;

S.CarouselArrow = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    _.get(theme, 'carousels.arrows.fontSize')
      ? `font-size: ${_.get(theme, 'carousels.arrows.fontSize')};`
      : ''}
  ${({ theme, arrowSize }) =>
    _.get(theme, ['carousels', 'arrows', arrowSize, 'width'])
      ? `width: ${_.get(theme, ['carousels', 'arrows', arrowSize, 'width'], 'auto')};`
      : ''}
  ${({ theme, arrowSize }) =>
    _.get(theme, ['carousels', 'arrows', arrowSize, 'height'])
      ? `height: ${_.get(theme, ['carousels', 'arrows', arrowSize, 'height'], 'auto')};`
      : ''}
  ${({ theme }) =>
    _.get(theme, 'carousels.arrows.background')
      ? `background: ${_.get(theme, 'carousels.arrows.background')};`
      : ''}
  ${({ theme }) => css`
    ${_.get(theme, 'carousels.arrows.customStyle', '')}
  `}
`;

const NextArrow = props => {
  return (
    <S.CarouselArrowWrapper {...props}>
      <S.CarouselArrow {...props}>
        <Icon className="nc-ic_keyboard_arrow_right_48px" />
      </S.CarouselArrow>
    </S.CarouselArrowWrapper>
  );
};

const PrevArrow = props => {
  return (
    <S.CarouselArrowWrapperPrev {...props}>
      <S.CarouselArrow {...props}>
        <Icon className="nc-ic_keyboard_arrow_left_48px " />
      </S.CarouselArrow>
    </S.CarouselArrowWrapperPrev>
  );
};

const StyledCarouselDots = styled.div`
  &.slick-dots {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      li {
        position: relative;
        display: block;
        margin: 0 5px 0 0;
        &:last-child {
          margin: 0;
        }
        button {
          cursor: pointer;
          font-size: 0;
          line-height: 0;
          display: block;
          width: ${({ theme }) => _.get(theme, 'carousels.dots.width', '10px')};
          height: ${({ theme }) =>
            _.get(theme, 'carousels.dots.width', '10px')};
          appearance: none;
          border: none;
          position: relative;
          outline: none;
          background: transparent;
          padding: 0;
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: ${({ theme }) =>
              _.get(theme, 'carousels.dots.width', '10px')};
            height: ${({ theme }) =>
              _.get(theme, 'carousels.dots.width', '10px')};
            border-radius: ${({ theme }) =>
              _.get(theme, 'carousels.dots.borderRadius', '100%')};
            background: ${({ theme }) =>
              _.get(theme, 'carousels.dots.background', '#000')};
          }
        }
        &.slick-active {
          button {
            &:before {
              background: ${({ theme }) =>
                _.get(theme, 'carousels.dots.isActive.background', '#000')};
            }
          }
        }
      }
    }
  }
`;

class AppCarousel extends PureComponent {
  defaultSliderSettings = {
    dots: false,
    infinite: true,
    speed: 1500,
    swipeToSlide: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true
  };
  render() {
    const {
      children,
      settings = {},
      CustomNextArrow = null,
      CustomPrevArrow = null,
      CustomDots = null,
      className = '',
      arrowSize = 'normal'
    } = this.props;
    const sliderControls = {};
    sliderControls.nextArrow = CustomNextArrow ? (
      <CustomNextArrow arrowSize={arrowSize} />
    ) : (
      <NextArrow arrowSize={arrowSize} />
    );
    sliderControls.prevArrow = CustomPrevArrow ? (
      <CustomPrevArrow arrowSize={arrowSize} />
    ) : (
      <PrevArrow arrowSize={arrowSize} />
    );
    sliderControls.appendDots = dots =>
      CustomDots ? (
        <CustomDots>
          <ul>{dots}</ul>
        </CustomDots>
      ) : (
        <StyledCarouselDots>
          <ul>{dots}</ul>
        </StyledCarouselDots>
      );
    const finalSettings = _.merge(
      {},
      this.defaultSliderSettings,
      settings,
      sliderControls
    );
    return (
      <S.CarouselWrapper>
        <Slider {...finalSettings} className={className}>
          {children}
        </Slider>
      </S.CarouselWrapper>
    );
  }
}

export default AppCarousel;
