import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import loadable from '@loadable/component';
import styled from 'styled-components';

import NotFound from 'common/components/errors/404'
import { getPageDataSelector } from 'common/selectors/page';

import { capfl } from 'common/utils';
import { withCssUnit } from 'common/components/styleguide/utils/index';

import { Container, Row, Column } from 'common/components/styleguide/grid';
import Image from 'common/components/_base/Image';
import List from 'common/components/styleguide/List';
import Link from 'common/components/styleguide/Link';
import { Headline1, Headline2 } from 'common/components/styleguide/Headline';
import Carousel from 'common/components/styleguide/Carousel';
import Icon from 'common/components/styleguide/Icon';
import Breadcrumbs from 'common/components/_partials/breadcrumbs/index';
import FarmerCard from './farmerCard';

import SS from 'common/pages/_style/index';

const Newsletter = loadable(
  () => import('common/blocks/functional/newsletter'),
  { ssr: false }
);

const S = {};

S.CarouselArrow = styled.button`
  &.slick-arrow {
    position: absolute;
    z-index: 1;
    line-height: 1;
    cursor: pointer;
    right: 20px;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding: 0;
    box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.15);
    margin-top: -20px;
    background: ${({ theme }) =>
      _.get(
        theme,
        'carousel.arrow.background',
        _.get(theme, 'colors.primary')
      )};
    color: ${({ theme }) =>
      _.get(theme, 'carousel.arrow.color', _.get(theme, 'colors.white'))};
    border: none;
    font-size: 16px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.xl)}) {
      margin-top: -25px;
      font-size: 22px;
      width: 50px;
      height: 50px;
    }
    &:hover {
      color: #000;
      background: #fff;
      box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.3);
      @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.xl)}) {
        color: #000;
        background: #fff;
      }
    }
    &:focus {
      outline: none;
    }
    &:before {
      display: none;
    }
    .icon {
      display: block;
    }
  }
`;

S.CarouselArrowPrev = styled(S.CarouselArrow)`
  &.slick-arrow {
    left: 20px;
    right: auto;
  }
`;

const NextArrow = (props) => {
  return (
    <S.CarouselArrow {...props}>
      <Icon className="nc-ic_keyboard_arrow_right_48px" />
    </S.CarouselArrow>
  );
};

const PrevArrow = (props) => {
  return (
    <S.CarouselArrowPrev {...props}>
      <Icon className="nc-ic_keyboard_arrow_left_48px " />
    </S.CarouselArrowPrev>
  );
};

const MainImage = ({
  photo = null,
  gallery = [],
  settings = {},
  className = '',
}) => {
  if (!_.isEmpty(gallery)) {
    return (
      <Carousel
        className={className}
        settings={settings}
        CustomNextArrow={NextArrow}
        CustomPrevArrow={PrevArrow}
      >
        {_.map(gallery, (image, index) => {
          return (
            <Image
              path={_.get(image, 'path')}
              title={_.get(image, 'title')}
              size="1220x680__cropped.jpg"
              key={'blog-gallery-image-' + _.get(image, 'id') + '-' + index}
            />
          );
        })}
      </Carousel>
    );
  }
  if (!_.isEmpty(photo)) {
    return (
      <Image {...photo} size="1220x680__cropped.jpg" className={className} />
    );
  }
  return null;
};

S.Breadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 20px;
`;

S.TitlesWrapper = styled.div`
  margin: 10px 0 35px 0;
`;

S.Headline1 = styled(Headline1)`
  justify-content: center;

  padding: 0 10px;
  font-size: 30px;
  span {
    text-align: center;
  }

  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    padding: 0 50px;
    font-size: 68px;
    line-height: 91px;
  }
`;

S.MainImage = styled(MainImage)`
  margin-bottom: 40px;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-bottom: 70px;
  }
`;

S.Subtitle = styled.div`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => _.get(theme, 'colors.primary', '#000')};
  font-family: ${({ theme }) =>
    _.get(theme, 'fontFamilySecondary', _.get(theme, 'fontFamily'))};
`;

S.TagsList = styled(List)`
  list-style-type: none;
  display: inline-block;
  margin: 0 10px;
  padding-left: 0;
`;

S.TagsListItem = styled.li`
  display: inline;

  &:not(:last-child) {
    &:after {
      content: ', ';
    }
  }
`;

S.TagsListItemLink = styled(Link)`
  text-decoration: none;
`;

S.Text = styled.div`
  padding: 20px 0;
`;

S.Tags = styled.div`
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin-top: 30px;
  font-weight: bold;
  font-size: 14px;
`;

S.Headline2 = styled(Headline2)`
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  span {
    text-align: center;
  }

  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    justify-content: flex-start;
    span {
      text-align: left;
    }
  }
`;

S.LatestArticles = styled.div`
  margin-bottom: 50px;
`;

const FarmerDetail = ({ article = null, latestArticles = [] }) => {
  const [t] = useTranslation();
  if (article) {
    const { category, photo, gallery } = article;
    const breadcrumbs = [
      {
        id: 'home',
        name: capfl(t('home')),
        link: {
          route: 'home',
        },
      },
      {
        id: 'farmers',
        name: capfl(t('farmers list')),
        link: {
          route: 'farmers',
        },
      },
      {
        id: _.get(article, 'id'),
        name: _.get(article, 'title'),
        urlName: _.get(article, 'urlTitle'),
      },
    ];

    return (
      <SS.Wrapper>
        <Container>
          <Row>
            <Column size={{ xs: 12 }}>
              <S.Breadcrumbs data={breadcrumbs} />
            </Column>
          </Row>
          <Row>
            <Column size={{ xs: 12 }}>
              <S.TitlesWrapper>
                <S.Headline1 color="black">
                  {_.get(article, 'title', '')}
                </S.Headline1>
                <S.Subtitle>{_.get(article, 'subtitle', '')}</S.Subtitle>
              </S.TitlesWrapper>
              {/* <S.MainImage photo={photo} gallery={gallery} /> */}
              <S.Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: _.get(article, 'text', ''),
                  }}
                />
              </S.Text>
            </Column>
          </Row>
          {!_.isEmpty(latestArticles) ? (
            <S.LatestArticles>
              <Row>
                <Column size={{ xs: 12 }}>
                  <S.Headline2 color="black">
                    {capfl(t('latest articles'))}
                  </S.Headline2>
                </Column>
              </Row>
              <Row>
                {_.map(latestArticles, (latestArticle, index) => (
                  <Column
                    size={{ xs: 12, md: 3 }}
                    key={'latest-article-' + _.get(latestArticle, 'id', index)}
                  >
                    <FarmerCard data={latestArticle} perexLength={100} />
                  </Column>
                ))}
              </Row>
            </S.LatestArticles>
          ) : null}
          <Row>
            <Column size={{ xs: 12 }}>
              <Newsletter
                config={{
                  title: capfl(t('newsletter')),
                  content: capfl(
                    t(
                      'send me information about new products and services, deals or recommendations by email'
                    )
                  ),
                }}
              />
            </Column>
          </Row>
        </Container>
      </SS.Wrapper>
    );
  }
  return <NotFound />;
};

const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  const mapStateToProps = (state, props) => {
    const pageData = getPageData(state, props);

    return {
      article: _.get(pageData, 'article', null),
      latestArticles: _.get(pageData, 'latestArticles', null),
    };
  };

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(FarmerDetail);
