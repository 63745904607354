import React, { Fragment, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';
import scrollIntoView from 'scroll-into-view-if-needed';
import loadable from '@loadable/component';

import { canUseDOM } from 'common/utils/index';
import { fireGTMEvent } from 'common/utils/gtm';
import { PRODUCT_DETAIL } from 'common/constants/GTMConstants';

import {
  Container,
  Row,
  Column,
  BlockTransparent,
  Block
} from 'common/components/styleguide/grid';

import RichSnippet from 'common/components/_partials/RichSnippet';
import Breadcrumbs from 'common/components/_partials/breadcrumbs/index';
import Sticker from 'common/components/_partials/sticker/_default/index.js';

import ProductTabs from 'common/components/product/detail/_default/tabs/index';
import Tags from 'common/components/product/detail/_default/Tags';
import RelatedProducts from 'common/components/product/detail/_default/RelatedProducts';
import CrosssaleProducts from 'common/components/product/detail/_default/CrosssaleProducts';
import NotFound from 'common/components/errors/404';

import loader from 'assets/images/loader.svg';
import { makeMapStateToProps, mapDispatchToProps } from './_shared';

import SS from 'common/pages/_style/index';

const Gallery = loadable(() => import('common/blocks/gallery'));
const ProductSummary = loadable(() =>
  import('common/blocks/product/detail/summary')
);

const S = {};
S.Column = styled(Column)`
  position: relative;
`;

class Product extends PureComponent {
  constructor(props) {
    super(props);
    this.refSectionDescription = React.createRef();
  }
  scrollToDescription = () => {
    if (canUseDOM()) {
      const node = ReactDOM.findDOMNode(this.refSectionDescription.current);
      if (node) {
        scrollIntoView(node, {
          scrollMode: 'if-needed',
          behavior: 'smooth'
        });
      }
    }
  };
  componentDidMount() {
    const { product, addToLastSeenProducts } = this.props;
    if (!_.isEmpty(product)) {
      addToLastSeenProducts(product);
      fireGTMEvent(PRODUCT_DETAIL, [product]);
    }
  }
  render() {
    const { product, breadcrumbs, isLoading = false, t } = this.props;

    if (_.isEmpty(product)) {
      return (
        <NotFound title={t('product not found')} imageAlternativeText="404" />
      );
    }

    const { id, name, relatedProducts = [], crosssaleProducts = [] } = product;
    const breadCrumbsCategorical = _.get(breadcrumbs, 'categorical', []);
    const breadCrumbsData = _.concat(breadCrumbsCategorical, [{ id, name }]);
    return (
      <Fragment>
        <Block
          backgroundColor={'backgroundDarker'}
          marginConfig={{}}
          paddingConfig={{ top: true, right: false, bottom: true, left: false }}
        >
          <Container>
            <Row>
              <Column size={{ xs: 12 }}>
                <BlockTransparent
                  marginConfig={{ bottom: true }}
                  paddingConfig={{}}
                >
                  <Breadcrumbs data={breadCrumbsData} withSameLevels={false} />
                </BlockTransparent>
              </Column>
            </Row>
            <Row>
              <S.Column size={{ xs: 12, md: 6, lg: 5 }}>
                <Sticker product={product} />
                <Gallery
                  image={_.get(product, 'photo', null)}
                  gallery={_.get(product, 'gallery', [])}
                  showThumbnails
                  alternativeImageTitle={_.get(product, 'name', null)}
                />
              </S.Column>
              <Column size={{ xs: 12, md: 6, lg: 7 }}>
                <ProductSummary
                  data={product}
                />
              </Column>
            </Row>
            <Row>
              <Column size={{ xs: 12 }}>
                <RelatedProducts data={relatedProducts} />
              </Column>
            </Row>
          </Container>
        </Block>
        <ProductTabs
          data={product}
          descriptionRef={this.refSectionDescription}
        />
        <Container>
          <Row>
            <Column size={{ xs: 12 }}>
              <Tags data={product} />
            </Column>
          </Row>
        </Container>
        <CrosssaleProducts data={crosssaleProducts} />
        {isLoading ? (
          <SS.Overlay>
            <img src={loader} alt="loader" />
          </SS.Overlay>
        ) : (
          ''
        )}
        <RichSnippet type="product" />
      </Fragment>
    );
  }
}

const ProductConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Product);

export default withTranslation()(ProductConnected);
