import styled from 'styled-components';
import _ from 'lodash';
import Icon from 'common/components/styleguide/Icon';
import { withCssUnit } from 'common/components/styleguide/utils/index';

const DEFAULT_SECTION_BACKGROUND = '#fff';
const DEFAULT_SECTION_BACKGROUND_SECOND = '#f7f7f8';
const DEFAULT_SECTION_PADDING = '20px 0';
const DEFAULT_SECTION_PADDING_MD = '40px 0';
const DEFAULT_SECTION_PADDING_LG = '60px 0 90px 0';

const DEFAULT_SECTION_TITLE_COLOR = '#969999';
const DEFAULT_SECTION_TITLE_FONT_SIZE = '1.375rem';
const DEFAULT_SECTION_TITLE_FONT_WEIGHT = 400;
const DEFAULT_SECTION_TITLE_ALIGN = 'center';
const DEFAULT_SECTION_TITLE_TEXT_TRANSFORM = 'none';

const DEFAULT_PAGE_BACKGROUND = '#fff';
const DEFAULT_PAGE_PADDING = '40px 0';


const DEFAULT_PAGE_OVERLAY_BACKGROUND = 'rgba(255, 255, 255, 0.8)';
const DEFAULT_PAGE_EMPTY_ICON_FONTSIZE = '38px';
const DEFAULT_PAGE_EMPTY_ICON_COLOR = '#ccc';

const SS = {};

SS.PosRelative = styled.div`
  position: relative;
`;

SS.Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) =>
    _.get(theme, 'page.background', DEFAULT_PAGE_BACKGROUND)};
  padding: ${({ theme }) => _.get(theme, 'page.padding', DEFAULT_PAGE_PADDING)};
`;

SS.WrapperSecondary = styled(SS.Wrapper)`
  background: ${({ theme }) => _.get(theme, 'page.backgroundSecondary', DEFAULT_PAGE_BACKGROUND)};
`;

SS.CartWrapper = styled(SS.Wrapper)`
  && {
    background: ${({ theme }) =>
      _.get(
        theme,
        'cart.background',
        _.get(theme, 'page.background', DEFAULT_PAGE_BACKGROUND)
      )};
  }
`;

SS.Overlay = styled.div`
  position: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'overlay.position',
      _.get(theme, 'page.overlay.position', 'fixed')
    )};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'overlay.background',
      _.get(theme, 'page.overlay.background', DEFAULT_PAGE_OVERLAY_BACKGROUND)
    )};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme, customStyle }) =>
    _.get(customStyle, 'overlay.zIndex', _.get(theme, 'zIndex.overlay'))};
`;

SS.EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

SS.EmptyMessageIcon = styled(Icon)`
  && {
    &.icon {
      font-size: ${({ theme, customStyle }) =>
        _.get(
          customStyle,
          'empty.icon.fontSize',
          _.get(
            theme,
            'page.empty.icon.fontSize',
            DEFAULT_PAGE_EMPTY_ICON_FONTSIZE
          )
        )};
      color: ${({ theme, customStyle }) =>
        _.get(
          customStyle,
          'empty.icon.color',
          _.get(theme, 'page.empty.icon.color', DEFAULT_PAGE_EMPTY_ICON_COLOR)
        )};
    }
  }
`;

SS.EmptyMessageText = styled.div`
  margin: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'empty.text.margin',
      _.get(theme, 'page.empty.text.margin')
    )};
  font-size: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'empty.text.fontSize',
      _.get(theme, 'page.empty.text.fontSize')
    )};
  font-weight: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'empty.text.fontWeight',
      _.get(theme, 'page.empty.text.fontWeight')
    )};
`;

SS.Section = styled.section`
  background: ${({ theme }) =>
    _.get(theme, 'section.background', DEFAULT_SECTION_BACKGROUND)};
  padding: ${({ theme }) =>
    _.get(theme, 'section.padding', DEFAULT_SECTION_PADDING)};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    padding: ${({ theme }) =>
      _.get(theme, 'section.md.padding', DEFAULT_SECTION_PADDING_MD)};
  }
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.lg)}) {
    padding: ${({ theme }) =>
      _.get(theme, 'section.lg.padding', DEFAULT_SECTION_PADDING_LG)};
  }
`;

SS.SectionTitle = styled.h2`
  color: ${({ theme }) =>
    _.get(theme, 'section.title.color', DEFAULT_SECTION_TITLE_COLOR)};
  font-size: ${({ theme }) =>
    _.get(theme, 'section.title.fontSize', DEFAULT_SECTION_TITLE_FONT_SIZE)};
  font-weight: ${({ theme }) =>
    _.get(theme, 'section.title.fontSize', DEFAULT_SECTION_TITLE_FONT_WEIGHT)};
  letter-spacing: 1px;
  margin: ${({ theme, gotSubtitle }) => {
    let defaultResult = gotSubtitle ? 0 : '0 0 20px 0';
    return _.get(theme, 'section.title.margin', defaultResult);
  }};
  text-align: ${({ theme }) =>
    _.get(theme, 'section.title.textAlign', DEFAULT_SECTION_TITLE_ALIGN)};
  text-transform: ${({ theme }) =>
    _.get(
      theme,
      'section.title.textTransform',
      DEFAULT_SECTION_TITLE_TEXT_TRANSFORM
    )};
`;

SS.SectionContent = styled.section`
  margin: 20px 0 0;
`;

SS.SectionGray = styled(SS.Section)`
  && {
    background: ${({ theme }) =>
      _.get(
        theme,
        'section.backgroundSecond',
        DEFAULT_SECTION_BACKGROUND_SECOND
      )};
    padding: 20px 0;
    @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
      padding: 40px 0;
    }
    @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.lg)}) {
      padding: 60px 0;
    }
  }
`;

SS.SectionFooterProducts = styled(SS.SectionGray)`
  && {
    padding: 40px 0 20px 0;
  }
`;

export default SS;
