import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { withCssUnit } from 'common/components/styleguide/utils';

import Image from 'common/components/_base/Image';
import Link from 'common/components/styleguide/Link';
import Carousel from 'common/components/styleguide/Carousel';
import ButtonRouteLink from 'common/components/_base/ButtonRouteLink';
import { BlockFlex } from 'common/components/styleguide/grid';

const CarouselItem = styled.section`
  width: 100%;
  position: relative;
  img {
    display: block;
    width: 100%;
  }
`;

const CarouselItemContent = styled(BlockFlex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) => withCssUnit(_.get(theme, 'gutterWidth', 20))};
  background-color: ${({ theme }) =>
    _.get(theme, 'banner.content.background', 'transparent')};
`;

const StyledItemTitle = styled.span`
  font-size: 25px;
  font-weight: 900;
  color: white;
  font-family: ${({ theme }) =>
    _.get(theme, 'banner.text.fontFamily', 'inherit')};
  margin-bottom: 10px;
  display: block;

  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    font-size: 42px;
  }

  -webkit-text-shadow: ${({ theme }) => _.get(theme, 'shadow.text', 'none')};
  -moz-text-shadow: ${({ theme }) => _.get(theme, 'shadow.text', 'none')};
  text-shadow: ${({ theme }) => _.get(theme, 'shadow.text', 'none')};
`;

const StyledItemSubtitle = styled.span`
  font-size: 13px;
  color: white;
  font-family: ${({ theme }) =>
    _.get(theme, 'banner.subtitle.fontFamily', 'inherit')};

  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    font-size: 17px;
  }
`;

const StyledButtonRouteLink = styled(ButtonRouteLink)`
  margin-top: 20px;
`;

const BannerVariant2 = ({
  items = [],
  scrSet = {
    '460px': '744x304.jpg',
    '780px': '1030x426.jpg',
  },
  size = '460x190.jpg',
  carouselSettings = { lazyLoad: true },
  showContent = false,
}) => {
  if (_.isEmpty(items)) {
    return null;
  }
  return (
    <Carousel settings={carouselSettings}>
      {items.map((item, i) => {
        return (
          <CarouselItem key={'banner-variant1-item-' + _.get(item, 'id', i)}>
            {item.url ? (
              <Link href={item.url}>
                <Image
                  {...item.photo}
                  srcSet={scrSet}
                  size={size}
                  sizes="100vw"
                  alt={
                    _.get(item, 'photo.description', false)
                      ? item.photo.description
                      : item.title
                  }
                  lazyload={false}
                />
              </Link>
            ) : (
              <Image
                {...item.photo}
                srcSet={scrSet}
                size={size}
                sizes="100vw"
                alt={
                  _.get(item, 'photo.description', false)
                    ? item.photo.description
                    : item.title
                }
                lazyload={false}
              />
            )}
            {showContent ? (
              <CarouselItemContent
                flexConfig={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {item.title && <StyledItemTitle>{item.title}</StyledItemTitle>}
                {item.subtitle && (
                  <StyledItemSubtitle>{item.subtitle}</StyledItemSubtitle>
                )}
                {item.url ? (
                  <StyledButtonRouteLink href={item.url}>
                    {item.text ? item.text : '>'}
                  </StyledButtonRouteLink>
                ) : null}
              </CarouselItemContent>
            ) : null}
          </CarouselItem>
        );
      })}
    </Carousel>
  );
};

export default BannerVariant2;
