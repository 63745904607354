import React from 'react';
import _ from 'lodash';

import ProductCarousel from 'common/components/_base/productCarousel/_default';

const FavouriteProductsVariant2 = ({
  data,
  theme,
  gtm
}) => {
  const settings = _.get(theme, 'favoritesCarouselSettings', {});
  if (!_.isEmpty(data) && !_.isEmpty(settings)) {
    return (
      <ProductCarousel
        settings={settings}
        data={data}
        type="favorites"
        gtm={gtm}
      />
    );
  }
  return null;
};

export default React.memo(FavouriteProductsVariant2);
