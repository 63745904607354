import styled from 'styled-components';
import _ from 'lodash';

const FormHint = styled.div`
  font-size: 0.8em;
  color: ${({ theme }) => _.get(theme, 'colors.gray', null)};
  margin-top: 5px;
  text-align: justify;
`;

export default FormHint;
