import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import { fireGTMEvent } from 'common/utils/gtm';
import { PRODUCT_VIEW } from 'common/constants/GTMConstants';

import styled from 'styled-components';
import { getLatestProductsSelector } from 'common/selectors/page';

import loadable from '@loadable/component';
const ProductCard = loadable(() => import(`common/blocks/product/card`));

import { Row, Column } from 'common/components/styleguide/grid';

class NewArrivals extends Component {
  componentDidMount() {
    const { products } = this.props;
    fireGTMEvent(PRODUCT_VIEW, products, 'HomepageNew')
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { theme, products } = this.props;
    const settings = _.get(theme, 'newArrivalsCarouselSettings', {});
    if (!_.isEmpty(products) && !_.isEmpty(settings)) {
      return (
        <Row>
          {_.map(products, (product, i) => {
            const productId = _.get(product, 'id', i);
            return (
              <Column
                size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 2 }}
                key={'products-listing-column-' + productId}
                paddingUseBottom={true}
              >
                <ProductCard
                  data={product}
                  showTitleOnTop={false}
                  showBrandOnTop={false}
                  showBrand={false}
                  showLabels={true}
                  lazyload={false}
                />
              </Column>
            );
          })}
        </Row>
      );
    }
    return null;
  }
}

const makeMapStateToProps = () => {
  const getLatestProducts = getLatestProductsSelector();
  return (state, props) => {
    return { products: getLatestProducts(state, props) };
  };
};

const NewArrivalsConnected = connect(makeMapStateToProps)(NewArrivals);

export default withTheme(NewArrivalsConnected);
