import React, { Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Item from 'common/components/_partials/breadcrumbs/Item';
import { getPageDataSelector } from 'common/selectors/page';
import List from 'common/components/styleguide/List';
import RichSnippet from 'common/components/_partials/RichSnippet';

const DEFUALT_LIST_ITEM_MARGIN = '0 8px 0 0';
const DEFUALT_SEPARATOR_COLOR = '#aaa';
const DEFUALT_SEPARATOR_FONT_SIZE = '0.8em';
const DEFUALT_SEPARATOR_MARGIN = '0 0 0 8px';

const S = {};

S.Breadcrumbs = styled(List)`
  && {
    flex-wrap: wrap;
  }
`;

S.ListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin: ${({ theme, customStyle }) =>
    _.get(
      customStyle,
      'listItem.margin',
      _.get(theme, 'breadcrumbs.listItem.margin', DEFUALT_LIST_ITEM_MARGIN)
    )};
  &:after {
    content: '/';
    display: block;
    line-height: 1;
    margin: ${({ theme, customStyle }) =>
      _.get(
        customStyle,
        'separator.margin',
        _.get(theme, 'breadcrumbs.separator.margin', DEFUALT_SEPARATOR_MARGIN)
      )};
    font-size: ${({ theme, customStyle }) =>
      _.get(
        customStyle,
        'separator.fontSize',
        _.get(
          theme,
          'breadcrumbs.separator.fontSize',
          DEFUALT_SEPARATOR_FONT_SIZE
        )
      )};
    color: ${({ theme, customStyle }) =>
      _.get(
        customStyle,
        'separator.color',
        _.get(theme, 'breadcrumbs.separator.color', DEFUALT_SEPARATOR_COLOR)
      )};
  }
  &:last-child {
    margin-right: 0;
    padding-right: 0;
    &:after {
      display: none;
    }
  }
`;

const Breadcrumbs = ({
  data,
  dataCategorical,
  theme,
  className,
  customStyle,
  withSameLevels = true
}) => {
  const breadcrumbsData = !_.isEmpty(data) ? data : dataCategorical;
  return (
    <Fragment>
      <S.Breadcrumbs className={className} inline reset>
        {_.map(breadcrumbsData, item => {
          const { id } = item;
          return (
            <S.ListItem
              theme={theme}
              key={'breadcrumbs-item-' + id}
              customStyle={customStyle}
            >
              <Item
                theme={theme}
                data={item}
                customStyle={customStyle}
                withSameLevels={withSameLevels}
              />
            </S.ListItem>
          );
        })}
      </S.Breadcrumbs>
      <RichSnippet type="breadcrumbs" />
    </Fragment>
  );
};

const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  return (state, props) => {
    return {
      dataCategorical: _.get(
        getPageData(state, props),
        'breadcrumbs.categorical',
        null
      )
    };
  };
};

const BreadcrumbsConnected = connect(makeMapStateToProps)(Breadcrumbs);

export default withTranslation()(BreadcrumbsConnected);
