import React from 'react';
import _ from 'lodash';

import ProductCarousel from 'common/components/_base/productCarousel/_default';

const NewProductsVariant1 = ({
  data,
  theme,
  gtm
}) => {
  const settings = _.get(theme, 'newArrivalsCarouselSettings', {});
  if (!_.isEmpty(data) && !_.isEmpty(settings)) {
    return (
      <ProductCarousel
        settings={settings}
        data={data}
        type="newarrivals"
        gtm={gtm}
      />
    );
  }
  return null;
};

export default React.memo(NewProductsVariant1);
