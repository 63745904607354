import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { capfl } from '../../../../utils/index';

import SS from 'common/components/product/_styles';
const S = {};

S.Title = styled.span`
    font-weight: 600;
`;

S.Item = styled.span`
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
`;

const Tags = ({ t, data }) => {
  const {
    tags
  } = data;

  if (_.isEmpty(tags)) {
    return null;
  }
  return (    
    <SS.Block>
        <S.Item>
            <S.Title>{capfl(t('tags'))}:</S.Title>
        </S.Item>
        {_.map(tags, tag => {
            return (
                <S.Item>
                    #{tag.name}
                </S.Item>
            );
        })}
    </SS.Block>
  );
};

export default withTranslation()(Tags);
