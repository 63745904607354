import React, { Component } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Row, Column } from 'common/components/styleguide/grid';
import Image from 'common/components/styleguide/Image';
import Link from 'common/components/styleguide/Link';
import RouteLink from 'common/components/_base/RouteLink';

import diamanty from 'assets/images/mobilonline/awards/icon_diamanty_2019.png';
import overenezakaznikmi from 'assets/images/mobilonline/awards/icon_overenezakaznikmi.png';
import forbes from 'assets/images/mobilonline/awards/icon_forbes.png';
import trend from 'assets/images/mobilonline/awards/icon_trend.png';
import { capfl } from 'common/utils/index';
import { withCssUnit } from 'common/components/styleguide/utils';

const StyledText = styled.div`
  font-size: 1.2em;
  color: ${({ theme }) => _.get(theme, 'colors.lgray', '#aaa')};
  font-weight: bold;
`;

const StyledAward = styled.div`
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  position: relative;

  ${({ hasSeparator }) =>
    hasSeparator &&
    css`
      &::after {
        content: '';
        height: 60%;
        width: 1px;
        border-right: 1px solid
          ${({ theme }) => _.get(theme, 'colors.lgray', '#aaa')};
        right: 0;
        position: absolute;
      }
    `};
`;

const StyledImage = styled(Image)`
  height: 50px;
  width: auto;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    height: 100px;
    margin-bottom: 0;
  }
`;

const StyledColumText = styled(Column)`
  display: none;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
  }
`;

class Awards extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { t } = this.props;
    return (
      <Row>
        <StyledColumText size={{ xs: 12, md: 4 }}>
          <StyledAward hasSeparator={true}>
            <StyledText>{capfl(t('our awards'))}</StyledText>
          </StyledAward>
        </StyledColumText>
        <Column size={{ xs: 6, md: 2 }}>
          <StyledAward>
            <Link
              href="/blog/diamanty-slovenskeho-biznisu"
            >
              <StyledImage alt={'diamanty slovenského biznisu'} src={diamanty} staticImage={true} />
            </Link>
          </StyledAward>
        </Column>
        <Column size={{ xs: 6, md: 2 }}>
          <StyledAward>
            <RouteLink
              route="blog-detail"
              params={{ id: 'mobilonline-pribeh' }}
            >
              <StyledImage alt={'časopis trend'} src={trend} staticImage={true} />
            </RouteLink>
          </StyledAward>
        </Column>
        <Column size={{ xs: 6, md: 2 }}>
          <StyledAward>
            <Link
              href="https://obchody.heureka.sk/mobilonline-sk/recenze/"
              target="_blank"
            >
              <StyledImage alt={'heureka overené zákazníkmi'} src={overenezakaznikmi} staticImage={true} />
            </Link>
          </StyledAward>
        </Column>
        <Column size={{ xs: 6, md: 2 }}>
          <StyledAward>
            <StyledImage alt={'časopis forbes'} src={forbes} staticImage={true} />
          </StyledAward>
        </Column>
      </Row>
    );
  }
}

export default withTranslation()(Awards);
