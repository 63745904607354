import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { sprintf } from 'sprintf-js';

import withDomain from 'common/hoc/withDomain';
import { Container, Row, Column } from 'common/components/styleguide/grid';

import { ATTACHMENT_TYPE_URL, ATTACHMENT_EXTENSION_PDF } from 'common/constants/SiteConstants';

import SS from 'common/components/product/_styles';
const S = {};

S.Block = styled(SS.Block)`
  padding: 10px;
  border: ${({ theme }) => _.get(theme, 'border.default.width')} ${({ theme }) => _.get(theme, 'border.default.style')} ${({ theme }) => _.get(theme, 'border.default.color')};
  &:hover {
    background: ${({ theme }) => _.get(theme, 'page.backgroundSecondary')};
  }
`;

S.Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => _.get(theme, 'colors.text')};
  display: block;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => _.get(theme, 'colors.primary')};
    text-decoration: underline;
  }
`;

export default withDomain(({ domain, data, t }) => {
  let absoluteUrl = '';
  let isLink = false;
  return (
    <Container>
      <Row>
        {_.map(data, attachment => {
          isLink =  _.toNumber(_.get(attachment, 'type', ATTACHMENT_TYPE_URL)) === ATTACHMENT_TYPE_URL;
          absoluteUrl = attachment.fileUrl;
          if (!isLink) {
            absoluteUrl = sprintf(
              '%1$s/%2$s',
              _.trimEnd(domain, '/'),
              _.trimStart(attachment.fileUrl, '/')
            );
          }
          return (
            <Column size={{ xs: 12, md: 6, lg: 4 }}>
              <S.Block>
                <S.Link
                  href={absoluteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attachment.fileName}
                </S.Link>
                {(!isLink && _.get(attachment, 'extension', '') === ATTACHMENT_EXTENSION_PDF) ? (
                  <SS.Block>
                    <object
                      aria-label={t('preview of attachment') + " " + attachment.fileName}
                      alt={t('preview of attachment') + " " + attachment.fileName}
                      data={absoluteUrl}
                      type="application/pdf"
                      width="100%"
                      height="350px"></object>
                  </SS.Block>
                ) : (
                  ''
                )}
              </S.Block>
            </Column>
          );
        })}
      </Row>
    </Container>
  );
});
