import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';

import { capfl } from 'common/utils';
import ProductCarousel from 'common/components/_base/productCarousel/_default/index';
import { Container, Row, Column } from 'common/components/styleguide/grid';

const S = {};

S.Title = styled.h3`
  margin: 0 0 25px 0;
  color: ${({ theme }) => _.get(theme, 'colors.text')};
  text-align: center;
`;

S.Wrapper = styled.section`
  margin-top: 20px;
  padding-top: 20px;
`;

class CrosssaleProducts extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { t, data, theme } = this.props;
    if (!_.isEmpty(data)) {
      const sliderSettings = _.get(theme, 'crosssaleCarouselSettings', {});
      return (
        <S.Wrapper>
          <Container>
            {_.map(data, (item, key) => {
              if (item.products) {
                return (
                  <Row key={'recommended-products-' + key}>
                    <Column size={{ xs: 12 }}>
                      <S.Title>{!_.isEmpty(item.name) ? item.name : capfl(t('recommended products'))}</S.Title>
                    </Column>                    
                    <Column size={{ xs: 12 }}>
                      <ProductCarousel 
                        useSmallCard={false} 
                        data={item.products} 
                        settings={sliderSettings} 
                        type={"recommended-products-carousel" + item.name} />
                    </Column>
                  </Row>
                );
              }
              return null;
            })}
          </Container>
        </S.Wrapper>
      );
    }
    return null;
  }
}

export default withTranslation()(
  withTheme(CrosssaleProducts)
);
