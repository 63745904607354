import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Select from './SelectCustom';
import FormHint from 'common/components/styleguide/form/_helpers/FormHint';
import FormError from 'common/components/styleguide/form/_helpers/FormError';
import FormLabel from 'common/components/styleguide/form/_helpers/FormLabel';

const StyledSelectCustomGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isInline }) => (isInline ? 'row' : 'column')};
  align-items: ${({ isInline }) => (isInline ? 'center' : 'flex-start')};
  margin-bottom: 20px;
`;

const StyledWrapper = styled.div`
  width: ${({ isInline }) => (isInline ? 'auto' : '100%')};
  flex: ${({ isInline }) => (isInline ? 1 : 'auto')};
  margin-right: ${({ isInline }) => (isInline ? '10px' : 0)};
`;

class SelectCustomGroup extends Component {
  handleSelectChange = value => {
    const { selectProps = {} } = this.props;
    const { name, setFieldValue, onChange } = selectProps;
    if (name && setFieldValue) {
      setFieldValue(name, value);
    }
    if (onChange) {
      onChange(value);
    }
  };
  handleSelectBlur = () => {
    const { selectProps = {} } = this.props;
    const { name, setFieldTouched, onBlur } = selectProps;
    if (name && setFieldTouched) {
      setFieldTouched(name);
    }
    if (onBlur) {
      onBlur();
    }
  };
  render() {
    const { className, selectProps = {}, label = '', error = '', hint = '', isInline = false } = this.props;
    const classNames = ['select-group'];
    if (className) {
      classNames.push(className);
    }
    return (
      <StyledSelectCustomGroup className={classNames.join(' ')} error={error} isInline={isInline}>
        {label ? (
          <FormLabel isInline={isInline} error={error} htmlFor={selectProps && selectProps.id ? selectProps.id : ''}>
            {label}
          </FormLabel>
        ) : (
          ''
        )}
        <StyledWrapper isInline={isInline}>
          <Select
            {...selectProps}
            error={error}
            isInline={isInline}
            onChange={this.handleSelectChange}
            onBlur={this.handleSelectBlur}
          />
          {error ? <FormError isInline={isInline}>{error}</FormError> : null}
          {hint ? <FormHint isInline={isInline}>{hint}</FormHint> : null}
        </StyledWrapper>
      </StyledSelectCustomGroup>
    );
  }
}

export default SelectCustomGroup;
