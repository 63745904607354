import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PubSub from 'pubsub-js';
import loadable from '@loadable/component';

import { getSearchResultsSelector } from 'common/selectors/search';
import { reactiveTemplateConfigSelector } from 'common/selectors/theme';

import {
  emptySearchSuggestions,
  fetchSearchSuggestions
} from 'common/actions/SearchActions';
import { capfl, canUseDOM, gotoRoute, setToObject } from 'common/utils';

const AutocompleteVariant = loadable(props =>
  import(`./search/${props.blockVariant}`), {
    ssr: true
  }
);
const Results = loadable(props => import(`./results/${props.blockVariant}`), {
  ssr: false
});

class Autocomplete extends PureComponent {
  state = {
    isFocused: false,
    value: '',
    typing: false,
    typingTimeout: 0
  };
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.mousedownSubKey = null;
    this.escapePressedSubKey = null;

    this.dataAttributes = {
      input: 'search-input',
      button: 'search-button'
    }
  }
  onFocus = e => {
    const { fetchResults } = this.props;
    const value = _.get(e, 'target.value', '');
    this.setState({ isFocused: true, value });
    fetchResults(value);
  };
  onChange = e => {
    const { fetchResults } = this.props;
    const value = _.get(e, 'target.value', '');
    this.setState({ isFocused: true, value });
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
   }
   this.setState({
    value: value,
    typing: true,
    typingTimeout: setTimeout(() => {
        this.setState({ typing: false });
        fetchResults(value);
      }, 1000)
    });
  };
  onBlur = () => {
    this.setState({ isFocused: false });
  };
  onKeyPress = e => {
    const { dispatchGotoRoute } = this.props;
    const value = _.get(e, 'target.value');
    if (e.key === 'Enter' && value) {
      dispatchGotoRoute('search', { searchPhrase: value });
    }
  };
  handleClickOutside = (msg, e) => {
    if (this.wrapperRef.current) {
      const { cleanResults, results } = this.props;
      const node = ReactDOM.findDOMNode(this.wrapperRef.current);
      if (!node.contains(e.target) && !_.isEmpty(results)) {
        cleanResults();
      }
    }
  };
  handleEscapePressed = (msg, e) => {
    if (this.wrapperRef.current) {
      const { cleanResults, results } = this.props;
      const node = ReactDOM.findDOMNode(this.wrapperRef.current);
      if (node.contains(e.target)) {
        this.setState({
          value: ''
        });
        if (!_.isEmpty(results)) {
          cleanResults();
        }
      }
    }
  };
  componentDidMount() {
    if (canUseDOM()) {
      this.mousedownSubKey = PubSub.subscribe(
        'MOUSEDOWN',
        this.handleClickOutside
      );
      this.escapePressedSubKey = PubSub.subscribe(
        'ESCPRESSED',
        this.handleEscapePressed
      );
    }
  }
  componentWillUnmount() {
    if (this.mousedownSubKey !== null) {
      PubSub.unsubscribe(this.mousedownSubKey);
    }
    if (this.escapePressedSubKey !== null) {
      PubSub.unsubscribe(this.escapePressedSubKey);
    }
  }
  render() {
    const { t, results, blockSettings } = this.props;
    const { isFocused, value, typing } = this.state;
    const searchData = {
      ref: this.wrapperRef,
      results: !_.isEmpty(results) ? (
        <Results
          blockVariant={_.get(blockSettings, 'blockSecondaryVariant')}
          searchValue={value}
          results={results}
        />
      ) : null,
      text: {
        placeholder: capfl(t('type to search')),
        button: capfl(t('search'))
      },
      functions: {
        onFocus: this.onFocus,
        onBlur: this.onBlur,
        onChange: this.onChange,
        onKeyPress: this.onKeyPress
      },
      isFocused,
      value
    };

    return (
      <AutocompleteVariant
        blockVariant={_.get(blockSettings, 'blockVariant')}
        dataAttributes={this.dataAttributes}
        typing={typing}
        {...searchData}
      />
    );
  }
}

const blockSettings = {};
const makeMapStateToProps = () => {
  const getSearchResults = getSearchResultsSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'AutocompleteVariant'
  );
  const reactiveTemplateConfigSecondary = reactiveTemplateConfigSelector(
    'AutocompleteResultsVariant'
  );
  return (state, props) => {
    return {
      results: getSearchResults(state, props),
      blockSettings: setToObject(blockSettings, {
        blockVariant: reactiveTemplateConfig(state, props),
        blockSecondaryVariant: reactiveTemplateConfigSecondary(state, props)
      })
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchResults: search => {
      dispatch(fetchSearchSuggestions(search));
    },
    cleanResults: () => {
      dispatch(emptySearchSuggestions());
    },
    dispatchGotoRoute: (route, params) => {
      dispatch(gotoRoute(route, params));
    }
  };
};

const AutocompleteConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Autocomplete);

export default withTranslation()(AutocompleteConnected);
