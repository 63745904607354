import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';

import { Row, Column } from 'common/components/styleguide/grid';
import { capfl } from 'common/utils/index';

const S = {};
S.HeadlineAdvantages = styled.h4`
  color: ${({ theme }) => _.get(theme, 'colors.success')};
`;

S.HeadlineDisadvantages = styled.h4`
  color: ${({ theme }) => _.get(theme, 'colors.danger')};
`;

const Advantages = ({ t, data }) => {
  const { advantages, disadvantages } = data;
  return (
      <Row>
        <Column size={{ md: 6 }}>
          <S.HeadlineAdvantages>+ {capfl(t('advantages'))}</S.HeadlineAdvantages>
          {_.get(advantages, 'description', '') ? (
            <div dangerouslySetInnerHTML={{ __html: _.get(advantages, 'description', '') }} />
          ) : (
            <p>{capfl(t('no advantages provided'))}</p>
          )}
        </Column>
        <Column size={{ md: 6 }}>
          <S.HeadlineDisadvantages>- {capfl(t('disadvantages'))}</S.HeadlineDisadvantages>
          {_.get(disadvantages, 'description', '') ? (
            <div dangerouslySetInnerHTML={{ __html: _.get(disadvantages, 'description', '') }} />
          ) : (
            <p>{capfl(t('no disadvantages provided'))}</p>
          )}
        </Column>
      </Row>
  );
};

export default withTranslation()(Advantages);
