import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import loadable from '@loadable/component';

const Link = loadable(() => import('common/components/styleguide/Link'));

import { withCssUnit } from 'common/components/styleguide/utils';
import { Block } from './grid/Block';

const StyledDropdown = styled.div`
  position: relative;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.sm)}) {
    position: static;
  }
`;

const DropdownContent = styled(Block)`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  position: absolute;
  top: 105%;
  ${({ position }) => (position + ': 0;')}
  min-width: 300px;
  border: ${({ theme }) => _.get(theme, 'dropdown.border', null)};
  box-shadow: ${({ theme }) => _.get(theme, 'dropdown.shadow', null)};
  z-index: ${({ theme }) => _.get(theme, 'zIndex.dropdown', 2)};
  border-radius: ${({ theme }) => _.get(theme, 'dropdown.borderRadius', null)};
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.sm)}) {
    width:100%;
  }
  ${({ customStyle }) => css`
    ${customStyle}
  `}
`;

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { isHidden: true };
    this.onShowClick = this.onShowClick.bind(this);
  }
  onShowClick(e) {
    e.preventDefault();
    this.setState(prevState => {
      return { isHidden: !prevState.isHidden };
    });
  }
  render() {
    const {
      showText = 'Dropdown',
      linkType = 'primary',
      onlyContent = false,
      isHidden = null,
      position = 'left',
      children,
      contentProperties = {
        marginConfig: {},
        customStyle: ''
        //all posible properties for Block
      }
    } = this.props;

    let isContentHidden = true;
    if (_.isNull(isHidden)) {
      const { isHidden: isHiddenInternalState = true } = this.state;
      isContentHidden = isHiddenInternalState;
    }
    else {
      isContentHidden = isHidden;
    }

    const content =  <DropdownContent position={position} isHidden={isContentHidden} {...contentProperties}>{children}</DropdownContent>

    if (onlyContent) {
      return (
        content
      );
    }

    return (
      <StyledDropdown>
        <Link href="." onClick={this.onShowClick} linkType={linkType}>
          {showText}
        </Link>
        {content}
      </StyledDropdown>
    );
  }
}

export default Dropdown;
