import { createSelector } from 'reselect';
import _ from 'lodash';

const getMenu = state => {
  const { menu = {} } = state;
  return menu;
};

export const getMenuSelector = () =>
  createSelector(
    [getMenu],
    menu => menu
  );

export const getMenuTopSelector = () =>
  createSelector(
    [getMenu],
    menu => _.map(menu, category => _.omit(category, 'children'))
  );
