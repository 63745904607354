import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import loadable from '@loadable/component';
import { getImagePath } from 'common/utils';
import { withCssUnit } from 'common/components/styleguide/utils';

const RouteLink = loadable(() => import('common/components/_base/RouteLink'), {
  ssr: true,
});
const Image = loadable(() => import('common/components/_base/Image'), {
  ssr: true,
});

const S = {};

S.BlogCard = styled.div`
  padding: 0;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  align-items: stretch;
  justify-content: space-between;
  transition: ${({ theme }) => _.get(theme, 'productCard.transition', 'none')};
  border-radius: ${({ theme }) =>
    _.get(theme, 'productCard.borderRadius', '0px')};
  ${({ theme }) =>
    _.get(theme, 'productCard.width')
      ? `width: ${_.get(theme, 'productCard.width')};`
      : ''}
  ${({ theme }) =>
    _.get(theme, 'productCard.background')
      ? `background: ${_.get(theme, 'productCard.background')};`
      : ''}
  ${({ theme }) =>
    _.get(theme, 'productCard.boxShadow')
      ? `box-shadow: ${_.get(theme, 'productCard.boxShadow')};`
      : ''}
  border: ${({ theme }) => _.get(theme, 'productCard.border', 'none')};
  &:hover {
    background: ${({ theme }) =>
      _.get(theme, 'productCard.hover.background', 'transparent')};
    border: ${({ theme }) => _.get(theme, 'productCard.hover.border', 'none')};
    ${({ theme }) =>
      _.get(theme, 'productCard.hover.boxShadow')
        ? `box-shadow: ${_.get(theme, 'productCard.hover.boxShadow')};`
        : ''}
  }

  & picture,
  & img {
    width: 100%;
  }
`;

S.BlogCardInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0;
  padding: 20px 15px;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    height: 190px;
    overflow: hidden;
    padding: 30px;
  }
`;

S.Title = styled(RouteLink)`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  ${({ theme }) =>
    `color: ${_.get(
      theme,
      'productCard.name.color',
      _.get(theme, 'colors.black')
    )};`}
  ${({ theme }) =>
    _.get(theme, 'productCard.name.fontFamily')
      ? `font-family: ${_.get(theme, 'productCard.name.fontFamily')};`
      : ''}

  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    font-size: 24px;
    margin-bottom: 15px;
  }

  &:hover {
    ${({ theme }) =>
      `color: ${_.get(
        theme,
        'productCard.name.color',
        _.get(theme, 'colors.text')
      )};`}
  }
`;

S.Perex = styled(RouteLink)`
  display: block;
  font-size: 11px;
  line-height: 20px;
  min-height: auto;
  font-weight: normal;
  color: #111111;
  font-family: ${({ theme }) => _.get(theme, 'fontFamily')};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    font-size: 12px;
    line-height: 24px;
  }
  &:hover {
    ${({ theme }) =>
      `color: ${_.get(
        theme,
        'productCard.desc.color',
        _.get(theme, 'colors.text')
      )};`}
  }
`;

const FarmerCard = ({
  data,
  theme,
  customStyle,
  imagePlaceholder,
  perexLength = 270
}) => {
  const [t] = useTranslation();
  const { title, photo, perex = '', urlTitle, publishTime, communityCategory } = data;
  return (
    <S.BlogCard customStyle={customStyle}>
      <RouteLink
        route="farmerDetail"
        params={{ id: urlTitle }}
        buttonType="primary"
      >
        {(photo || imagePlaceholder) && (
          <Image
            className="blog-card__img"
            path={_.get(photo, 'path', getImagePath(imagePlaceholder))}
            size={`600x350__cropped.jpg`}
            alt={_.get(photo, 'title', title)}
            lazyload={true}
          />
      )}
      </RouteLink>
      <S.BlogCardInner
        marginConfig={{}}
        paddingConfig={{}}
        flexConfig={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          alignContent: 'center'
        }}
      >
        <S.Title
          route="farmerDetail"
          params={{ id: urlTitle }}
        >
          {title}
        </S.Title>
        <S.Perex
          route="farmerDetail"
          params={{ id: urlTitle }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: perex
                ? _.truncate(perex, {
                  'length': perexLength,
                  'omission': '...'
                })
                : ''
            }}
          ></div>
        </S.Perex>
      </S.BlogCardInner>
    </S.BlogCard>
  );
};

export default React.memo(FarmerCard);
