import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

const Column = loadable(() =>
  import('common/components/styleguide/grid/Column')
);
const Row = loadable(() =>
  import('common/components/styleguide/grid/Row')
);
const Container = loadable(() =>
  import('common/components/styleguide/grid/Container')
);

import { getPageDataSelector } from 'common/selectors/page';

class Static extends PureComponent {
  render() {
    const { data, useContainer = true } = this.props;
    if (_.isEmpty(data)) {
      return null;
    }
    const content = (
        _.map(data, item => {
          item.title = null;
          return (
            <Fragment key={'static-page-' + _.get(item, 'id')}>
              {_.get(item, 'customCss') ? (
                <style
                  dangerouslySetInnerHTML={{
                    __html: _.get(item, 'customCss')
                  }}
                />
              ) : null}
              {_.get(item, 'customJavascript') ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: _.get(item, 'customJavascript')
                  }}
                />
              ) : null}
              <div
                dangerouslySetInnerHTML={{
                  __html: _.get(item, 'content', '')
                }}
              />
            </Fragment>
          );
        })
    );

    return (
      <Fragment>
        {
          useContainer ? (
            <Container>
              <Row>
                <Column size={{ xs: 12 }}>{content}</Column>
              </Row>
            </Container>
          ) : content
        }
      </Fragment>
    )
  }
}

const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  return (state, props) => {
    return {
      data: _.get(getPageData(state, props), _.get(props, 'location', 'staticPages'))
    };
  };
};

const StaticConnected = connect(makeMapStateToProps)(Static);

export default StaticConnected;
