import { createSelector } from 'reselect';
import _ from 'lodash';

const getSearch = state => {
  const { search = {} } = state;
  return search;
};

export const getSearchResultsSelector = () =>
  createSelector(
    [getSearch],
    search => _.get(search, 'searchResults', null)
  );
