import styled from 'styled-components';
import _ from 'lodash';

import Hint from 'common/components/styleguide/form/_helpers/FormHint';

const FormError = styled(Hint)`
  color: ${({ theme }) => _.get(theme, 'colors.danger', null)};
`;

export default FormError;
