import React from 'react';
import BannerVariant from 'common/blocks/banners/BannerVariant2';

const BannerVariant6 = ({
  items = [],
  scrSet = {
    '460w': '800x150.jpg',
    '780w': '1200x225.jpg'
  },
  size = '400x75.jpg'
  }) => {
  return (
    <BannerVariant items={items} scrSet={scrSet} size={size} />
  );
};

export default BannerVariant6;
