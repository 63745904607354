import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Container, Row, Column } from 'common/components/styleguide/grid';
// import loadable from '@loadable/component';

import { RETARGETING_CATEGORIES } from 'common/constants/GTMConstants';

import { fireGTMEvent } from 'common/utils/gtm';
import { withCssUnit } from 'common/components/styleguide/utils/index';

import { capfl } from 'common/utils/index';

import HomeBanners from 'common/components/home/banners/mobilonline/index';
import Favorites from 'common/components/home/favorites';
import NewArrivals from 'common/components/home/newarrivals';
import MarketingPicks from 'common/components/home/marketingPicks/mobilonline/index';
import Awards from 'common/components/home/awards/mobilonline';
import Articles from 'common/components/home/articles/mobilonline';
import Newsletter from 'common/components/home/newsletter/mobilonline';
import Static from 'common/components/home/static';

// const Static = loadable(() => import('common/components/home/static'), {
//   ssr: false
// });

import SS from 'common/pages/_style/index';

const StyledSection = SS.Section;
const StyledSectionTitle = SS.SectionTitle;
const StyledSectionGray = SS.SectionGray;

const StyledSectionTitleCustom = styled(StyledSectionTitle)`
  text-align: center;
  margin: 0 0 10px 0;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    text-align: ${({ theme }) =>
      _.get(theme, 'section.title.textAlign', 'left')};
    margin: ${({ theme }) =>
      _.get(theme, 'section.title.margin', '0 0 20px 0')};
  }
`;

class Home extends Component {
  componentDidMount() {
    fireGTMEvent(RETARGETING_CATEGORIES, [{ name: 'Homepage' }]);
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <HomeBanners />
        <StyledSection style={{paddingBottom: 0}}>
          <Container>
            <Row>
              <Column size={{ xs: 12 }}>
                <StyledSectionTitleCustom>
                  {capfl(t('special offer'))}
                </StyledSectionTitleCustom>
              </Column>
            </Row>
            <Row>
              <Column size={{ xs: 12 }} style={{overflow: 'hidden'}}>
                <Favorites />
              </Column>
            </Row>
          </Container>
        </StyledSection>
        <StyledSection>
          <Container>
            <Row>
              <Column size={{ xs: 12 }}>
                <StyledSectionTitleCustom>
                  {capfl(t('latest offers'))}
                </StyledSectionTitleCustom>
              </Column>
            </Row>
            <Row>
              <Column size={{ xs: 12 }}>
                <NewArrivals />
              </Column>
            </Row>
          </Container>
        </StyledSection>
        <StyledSectionGray>
          <Container>
            <Column size={{ xs: 12 }}>
              <MarketingPicks />
            </Column>
          </Container>
        </StyledSectionGray>
        <StyledSection>
          <Container>
            <Row>
              <Column size={{ xs: 12 }}>
                <Static />
              </Column>
              <Column size={{ xs: 12 }}>
                <Awards />
              </Column>
            </Row>
          </Container>
        </StyledSection>
        <StyledSectionGray>
          <Container>
            <Row>
              <Column size={{ xs: 12 }}>
                <StyledSectionTitleCustom>
                  {capfl(t('articles'))}
                </StyledSectionTitleCustom>
              </Column>
            </Row>
            <Row>
              <Column size={{ xs: 12 }}>
                <Articles />
              </Column>
            </Row>
          </Container>
        </StyledSectionGray>
        <Newsletter />
      </Fragment>
    );
  }
}

export default withTranslation()(Home);
