import _ from 'lodash';
import { getPageDataSelector, getPageIsLoadingSelector } from 'common/selectors/page';
import { addProductToLastSeen } from 'common/actions/PageActions';

export const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  const getPageIsLoading = getPageIsLoadingSelector();
  const mapStateToProps = (state, props) => {
    return {
      product: _.get(getPageData(state, props), 'product'),
      breadcrumbs: _.get(getPageData(state, props), 'breadcrumbs'),
      isLoading: getPageIsLoading(state, props)
    };
  };
  return mapStateToProps;
};

export const mapDispatchToProps = dispatch => {
  return {
    addToLastSeenProducts: data => {
      dispatch(addProductToLastSeen(data));
    }
  };
};
