import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { withCssUnit } from 'common/components/styleguide/utils';

import Image from 'common/components/_base/Image';
import ButtonRouteLink from 'common/components/_base/ButtonRouteLink';
import Carousel from 'common/components/styleguide/Carousel';

import { BlockFlex } from 'common/components/styleguide/grid';

const CarouselItem = styled.section`
  width: 100%;
  position: relative;
  img {
    display: block;
    width: 100%;
  }
`;

const CarouselItemContent = styled(BlockFlex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) => withCssUnit(_.get(theme, 'gutterWidth', 20))};

  span {
    font-size: 4em;
    font-weight: 900;
    color: white;
    font-family: ${({ theme }) => _.get(theme, 'banner.text.fontFamily', 'inherit')};

    @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
      font-size:1.5em;
    }

    -webkit-text-shadow: ${({ theme }) => _.get(theme, 'shadow.text', 'none')};
    -moz-text-shadow: ${({ theme }) => _.get(theme, 'shadow.text', 'none')};
    text-shadow: ${({ theme }) => _.get(theme, 'shadow.text', 'none')};
  }
`;

const BannerVariant7 = ({
  items = [],
  scrSet = {
    '460w': '725x350.jpg',
    '780w': '1450x700.jpg'
  },
  size = '725x350.jpg',
  carouselSettins = {}
}) => {
  return (
    <Carousel settings={carouselSettins}>
      {items.map((item, i) => {
        return (
          <CarouselItem key={'banner-variant1-item-' + _.get(item, 'id', i)}>
            <Image
              {...item.photo}
              srcSet={scrSet}
              size={size}
              sizes="100vw"
              alt={
                _.get(item, 'photo.description', false)
                  ? item.photo.description
                  : item.title
              }
              lazyloadPlaceholder={
                <Image {...item.photo} lazyload={false} size={size} />
              }
            />
            <CarouselItemContent
              flexConfig={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {item.title && (<span>{item.title}</span>)}
              {item.subtitle && (<span>{item.subtitle}</span>)}
              {item.url ? (
                <ButtonRouteLink href={item.url}>{item.text ? item.text : '> > >'}</ButtonRouteLink>
              ) : null}
            </CarouselItemContent>
          </CarouselItem>
        );
      })}
    </Carousel>
  );
};

export default BannerVariant7;
