import React, { PureComponent, Fragment } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { withTranslation } from "react-i18next";
import styled, { withTheme } from 'styled-components';

import { PARAM_MODEL_ID, PARAM_BRAND_ID } from 'common/constants/ProductConstants';

import { capfl, setRouteParams, gotoUrl } from 'common/utils';
import { getPageDataSelector } from "common/selectors/page";
import { getActiveThemeSelector, getLanguageSelector } from "common/selectors/index";
import { fetchQuickFilterData, togglePageModal } from 'common/actions/PageActions';

import loader from 'assets/images/loader.svg';
import SelectGroup from "common/components/styleguide/form/SelectCustomGroup";
import ButtonRouteLink from "common/components/_base/ButtonRouteLink";
import QuickSearchInfoModal from './Modal'

const S = {};

S.SearchBox = styled.section`
  display: flex;
  width: 100%;
  height: 203px;
  background: ${({ theme }) => _.get(theme, 'colors.secondary')};
  justify-content: center;
  justify-items: center;
  padding: 15px 20px;
  flex-wrap: wrap;
`;

S.Title = styled.span`
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
  font-weight: bold;
  font-size: 1.05em;
  margin-bottom: 15px;
`;

S.SearchFormWrapper = styled.section`
  justify-items: center;
  align-items: center;
  width: 100%;
`;

S.SelectGroup = styled(SelectGroup)`
  margin-bottom: 15px;
`;

S.SelectboxWrapper = styled.div`
  position: relative;
`;

S.SelectboxLoaderOverlay = styled.div`
  background: rgba(255,255,255,0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
`;

S.SelectboxLoader = styled.img`
  height: 20px;
  display: flex;
  margin: 0 auto;
`;

S.SearchBoxFooter = styled.div`
  display: flex;
  justify-content space-between;
  align-items: center;

  & > span {
    color: ${({ theme }) => _.get(theme, 'colors.primary')};
    font-size: 0.75em;
    cursor: pointer;
    text-decoration: underline;
  }
`;

S.Button = styled(ButtonRouteLink)`
  background: ${({ theme }) => _.get(theme, 'colors.white')};
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
  font-weight: bolder;
  border-radius: 20px;
  font-size: 0.75em;
  padding: 5px 15px;
  &:hover {
    color: white;
    text-decoration: none;
  }
`;

S.Loader = styled.img`
  display: flex;
  margin: 0 auto;
`;

class QuickSearch extends PureComponent {
  state = {
    isClient: false,
    brand: null,
    model: null,
    modelIsLoading: false
  };
  componentDidMount() {
    this.setState({ isClient: true });
    const { dispatchFetchQuickFilterData } = this.props;
    dispatchFetchQuickFilterData('prislusenstvo-na-tvoj-mobil');
  }
  handleOnBrandChange = (value) => {
    if (value) {
      this.setState({ modelIsLoading: true });
      const { dispatchFetchQuickFilterData } = this.props;
      dispatchFetchQuickFilterData('prislusenstvo', { param: { [PARAM_BRAND_ID]: [value]} }).then(() => {
        this.setState({brand: value});
        this.setState({ modelIsLoading: false });
      });
    }
  };
  handleOnModelChange = (value) => {
    if (value) {
      this.setState({model: value});
    }
  };
  onShowModalClick = (e) => {
    e.preventDefault();
    const { dispatchShowModal } = this.props;
    dispatchShowModal();
  };
  onButtonClick = (e) => {
    e.preventDefault();
    const { data, currentLanguage, activeTheme } = this.props;
    const filterParams = _.get(data, 'parameters', []);
    const routeParams = {
      id: 'prislusenstvo-na-tvoj-mobil',
      filters: 'filter/param/'
    };
    if (_.get(this.state, 'brand')) {
      const brandParam = _.find(filterParams, ['id', PARAM_BRAND_ID]);
      if (brandParam) {
        routeParams.filters += _.get(brandParam, 'v2-name') + '-' + _.get(brandParam, 'id') + '/' + _.get(this.state, 'brand', null) + '/';
      }
    }
    if (_.get(this.state, 'model')) {
      const modelParam = _.find(filterParams, ['id', PARAM_MODEL_ID]);
      if (modelParam) {
        routeParams.filters += _.get(modelParam, 'v2-name') + '-' + _.get(modelParam, 'id') + '/' + _.get(this.state, 'model', null) + '/';
      }
    }
    const url = setRouteParams('category', routeParams, currentLanguage, activeTheme, { encode: (value) => value });
    gotoUrl(url);
  };
  render() {
    if (!_.get(this.state, 'isClient', false)) {
      return null;
    }
    const { data, theme, t } = this.props;
    const brands = [];
    const brandParam = _.find(_.get(data,'parameters'), ['id', PARAM_BRAND_ID]);
    _.each(_.get(brandParam, 'values'), brand => {
      brands.push({ value: _.get(brand, 'v2-value'), label: _.get(brand, 'v2-label')});
    });
    const models = [];
    if (this.state.brand) {
      const modelParam = _.find(_.get(data,'parameters'), ['id', PARAM_MODEL_ID]);
      _.each(_.get(modelParam, 'values'), model => {
        models.push({ value: _.get(model, 'v2-value'), label: _.get(model, 'v2-label')});
      });
    }
    return (
      <S.SearchBox>
        <S.Title>{capfl(t('accessories quick search'))}</S.Title>
        <S.SearchFormWrapper>
          {!_.isEmpty(brands) ? (
            <Fragment>
              <S.SelectGroup
                selectProps={{
                  id: 'brand',
                  name: 'brand',
                  value: null,
                  options: brands,
                  prompt: { value: '', label: t('choose brand')},
                  customStyleWrapper: {
                    borderRadius: '50px'
                  },
                  customStyle: {
                    fontSize: '0.9em',
                    color: _.get(theme, 'colors.dgray'),
                    minHeight: '30px',
                    lineHeight: '30px',
                    background: _.get(theme, 'colors.white'),
                    paddingLeft: '15px',
                    paddingRight: '20px'
                  },
                  onChange: (value) => { this.handleOnBrandChange(value); }
                }}
              />
              <S.SelectboxWrapper>
                <S.SelectGroup
                  selectProps={{
                    id: 'model',
                    name: 'model',
                    value: null,
                    options: _.sortBy(models, 'label'),
                    prompt: { value: '', label: t('choose model')},
                    customStyleWrapper: {
                      borderRadius: '50px'
                    },
                    customStyle: {
                      fontSize: '0.9em',
                      color: _.get(theme, 'colors.dgray'),
                      minHeight: '30px',
                      lineHeight: '30px',
                      background: _.get(theme, 'colors.white'),
                      paddingLeft: '15px',
                      paddingRight: '20px'
                    },
                    onChange: (value) => { this.handleOnModelChange(value); }
                  }}
                />
                {this.state.modelIsLoading ? (
                  <S.SelectboxLoaderOverlay>
                    <S.SelectboxLoader src={loader} alt="loader" />
                  </S.SelectboxLoaderOverlay>
                ) : (
                  ''
                )}
              </S.SelectboxWrapper>
              <S.SearchBoxFooter>
                <span onClick={this.onShowModalClick}>{capfl(t('how to determine telephone model?'))}</span>
                <S.Button href="#" onClick={this.onButtonClick} buttonSize="small">{capfl(t('show'))}</S.Button>
              </S.SearchBoxFooter>
            </Fragment>
          ) : (
            <S.Loader src={loader} alt="loader" />
          )}
        </S.SearchFormWrapper>
        <QuickSearchInfoModal />
      </S.SearchBox>
    )
  }
}

const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  const getActiveTheme = getActiveThemeSelector();
  const getLanguage = getLanguageSelector();
  return (state, props) => {
    const pageData = getPageData(state, props);
    return {
      data: _.get(pageData, 'quickFilter', null),
      activeTheme: getActiveTheme(state, props),
      currentLanguage: getLanguage(state, props)
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchFetchQuickFilterData: (categoryName, dataObject = {}) =>  {
      return dispatch(fetchQuickFilterData(categoryName, dataObject));
    },
    dispatchShowModal: () => {
      dispatch(togglePageModal('quickSearchModal', true));
    }
  };
};

const QuickSearchConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(QuickSearch);

export default withTranslation()(
  withTheme(QuickSearchConnected)
);
