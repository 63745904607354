import React from 'react';
import styled from 'styled-components';

import SS from 'common/components/product/_styles';
const S = {};

S.Block = styled(SS.Block)`
  ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
`;

export default React.forwardRef(({ data }, ref) => {
  return (
    <S.Block ref={ref} dangerouslySetInnerHTML={{ __html: data.description }} />
  );
});
