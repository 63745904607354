import React from 'react';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { capfl } from 'common/utils/index';

import { Container, Row, Column } from 'common/components/styleguide/grid';

import NotFound from 'common/components/NotFound';
import NewArrivals from 'common/components/home/newarrivals/index';

const S = {};
S.Wrapper = styled.section`
  margin: 0 0 60px 0;
`;

S.Title = styled.div`
  margin: 20px 0 30px 0;
  color: ${({ theme }) => _.get(theme, 'colors.secondary', '#000')}
  padding-top: 30px;
  border-top: 1px solid rgba(0,0,0,0.1);
  font-size: 25px;
  text-align: center;
  font-weight: bold;
`;

const Error404 = ({ t }) => {
  return (
    <S.Wrapper>
      <NotFound text = 'the page you are looking for might be deleted, renamed or temporary unavailable.' imageAlternativeText="404"/>
      <Container>
        <Row>
          <Column size={{ xs: 12 }}>
            <S.Title>{capfl(t('you may be interested in'))}</S.Title>
            <NewArrivals />
          </Column>
        </Row>
      </Container>
    </S.Wrapper>
  );
}


export default withTranslation()(withTheme(Error404));
