import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { capfl } from 'common/utils';
import { Container, Row, Column } from 'common/components/styleguide/grid';
import Image from 'common/components/styleguide/Image';
import ButtonRouteLink from 'common/components/_base/ButtonRouteLink';

const StyledSection = styled.section`
  text-align: center;
  margin: 40px 0;
`;

const StyledTitle = styled.h4`
  color: ${({ theme }) => _.get(theme, 'colors.primary', '#000')};
  font-size: 25px;
`;

const StyledImage = styled(Image)`
  margin-top: 30px;
  width: 150px;
  display: block;
  margin: 0 auto;
`;

const StyledImageAlternativeText = styled.strong`
  margin-top: 30px;
  font-size: 150px;
  color: rgba(0, 0, 0, 0.15);
`;

const StyledButtonRouteLink = styled(ButtonRouteLink)`
  max-width: 300px;
  margin: 30px auto 0 auto;
`;

const NotFound = ({
  t,
  title = "can't find this page",
  text,
  image = null,
  imageAlternativeText = null,
  columnSize = { xs: 12 }
}) => {
  return (
    <Container>
      <Row>
        <Column size={columnSize}>
          <StyledSection>
            {image ? (
              <StyledImage src={image} lazyload={false} staticImage={true} />
            ) : (
              <StyledImageAlternativeText>
                {imageAlternativeText}
              </StyledImageAlternativeText>
            )}
            <StyledTitle>{capfl(t(title))}</StyledTitle>
            {text ? <p>{capfl(t(text))}</p> : ''}
            <StyledButtonRouteLink route="home">
              {capfl(t('back to homepage'))}
            </StyledButtonRouteLink>
          </StyledSection>
        </Column>
      </Row>
    </Container>
  );
};

export default React.memo(withTranslation()(NotFound));
