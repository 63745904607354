import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import { getFavoritesProductsSelector } from '../../../selectors/page';

import ProductCarousel from 'common/components/_base/productCarousel/_default';

import { fireGTMEvent } from 'common/utils/gtm';
import { PRODUCT_VIEW } from 'common/constants/GTMConstants';

class Favorites extends Component {
  componentDidMount() {
    const { products } = this.props;
    fireGTMEvent(PRODUCT_VIEW, products, 'HomepageFavourite');
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { theme, products } = this.props;
    const settings = _.get(theme, 'favoritesCarouselSettings', {});
    if (!_.isEmpty(products) && !_.isEmpty(settings)) {
      return (
        <ProductCarousel
          settings={settings}
          data={products}
          type="favorites"
          gtm={{ list: 'HomepageFavourite' }}
        />
      );
    }
    return null;
  }
}

const makeMapStateToProps = () => {
  const getFavoritesProducts = getFavoritesProductsSelector();
  return (state, props) => {
    return { products: getFavoritesProducts(state, props) };
  };
};

const FavoritesConnected = connect(makeMapStateToProps)(Favorites);

export default withTheme(FavoritesConnected);
