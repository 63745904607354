import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import chroma from 'chroma-js';
import { Tab, Tabs, TabList, TabPanel, resetIdCounter } from 'react-tabs';

import {
  PRODUCT_ADDITIONAL_INFO_TYPE_SIZE
} from 'common/constants/ProductConstants';

import { Container, Row, Column } from 'common/components/styleguide/grid';
import { withCssUnit } from 'common/components/styleguide/utils';
import { capfl } from 'common/utils/index';

import Description from './Description';
import Parameters from './Parameters';
import Advantages from './Advantages';
import Attachments from './Attachments';
import AdditionalInfos from './AdditionalInfos';
import Ratings from './Ratings';
const S = {};

S.Tabs = styled(Tabs)`
  background: ${({ theme }) => _.get(theme, 'colors.backgroundSecondary')};
`;

S.TabListWrappper = styled.section`
  box-shadow: inset 0px -1px 0px 0px ${({ theme }) => _.get(theme, 'border.default.color')};
  background: ${({ theme }) => chroma(_.get(theme, 'colors.black')).alpha(0.015)};
  position: relative;
  ${({ rightOverflowVisible }) =>
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 70px;
        z-index: 1;
        pointer-events: none;
        background: linear-gradient(to left,rgba(2255,255,255,95%),rgba(255,255,255,0));
        visibility: visible;
        opacity: ${rightOverflowVisible ? 1 : 0};
        transition: opacity .3s 0s,visibility 0s 0s;
      };
    `
  }
  ${({ leftOverflowVisible }) =>
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 70px;
        z-index: 1;
        pointer-events: none;
        background: linear-gradient(to right,rgba(2255,255,255,95%),rgba(255,255,255,0));
        visibility: visible;
        opacity: ${leftOverflowVisible ? 1 : 0};
        transition: opacity .3s 0s,visibility 0s 0s;
      };
    `
  }
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
   background: transparent;
  }
`;

S.TabsContentWrapper = styled.section`
  padding-top: 20px;
  background: ${({ theme }) => _.get(theme, 'page.background')};
`;

S.TabList = styled(TabList)`
  margin: 0;
  list-style-type: none;
  padding: 0;
  display: flex;
  overflow-x: auto;
`;

S.Tab = styled(Tab)`
  cursor: pointer;
  outline: none;
  border: none;
  color: ${({ theme }) => _.get(theme, 'product.tab.fontColor', '#000000')};
  border-bottom: ${({ theme }) => _.get(theme, 'product.tab.borderBottom', 'none')};
  padding: 20px;
  font-weight: ${({ theme }) => _.get(theme, 'product.tab.fontWeight', 'normal')};
  font-size: ${({ theme }) => _.get(theme, 'product.tab.fontSize', '1em')};
  text-decoration: none;
  background: transparent;
  border-radius: ${({ theme }) => _.get(theme, 'product.tab.borderRadius', '0')};
  text-align: center;

  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    background: ${({ theme }) => _.get(theme, 'product.tab.background')};
    padding: ${({ theme }) => _.get(theme, 'product.tab.padding', '20px 40px')};
    &:not(:last-child) {
      margin-right: ${({ theme }) => _.get(theme, 'product.tab.marginRight')};
    }
  }
  &:hover {
    text-decoration: ${({ theme }) => _.get(theme, 'product.tab.hover.textDecoration', 'none')};
    background: ${({ theme }) => _.get(theme, 'product.tab.hover.background')};
    color: ${({ theme }) => _.get(theme, 'product.tab.hover.fontColor')};
  }
  &.react-tabs__tab--selected {
    color: ${({ theme }) => _.get(theme, 'product.tab.active.fontColor')};
    background: ${({ theme }) => _.get(theme, 'product.tab.active.background')};
    text-decoration: none;
    border: ${({ theme }) => _.get(theme, 'border.default.width')} ${({ theme }) => _.get(theme, 'border.default.style')} ${({ theme }) => _.get(theme, 'border.default.color')};
    border-bottom: 0;

    @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
      border-top-right-radius: ${({ theme }) => _.get(theme, 'border.components.radius', _.get(theme, 'border.default.radius'))};
      border-top-left-radius: ${({ theme }) => _.get(theme, 'border.components.radius', _.get(theme, 'border.default.radius'))};
    }
  }
`;

class ProductTabs extends PureComponent {
  state = {
    rightOverflowVisible: false,
    leftOverflowVisible: false,
  }
  constructor(props) {
    super(props);
    this.tabListRef = React.createRef();
  }
  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.tabListRef.current);
    if (node) {
      this.setOverflowStates(node)
    }
  }
  onTabListScroll = e => {
    this.setOverflowStates(e.target)
  }
  setOverflowStates = element => {
    const visibleWidth = element.offsetWidth;
    const totalWidth = element.scrollWidth;
    const scrollPosition = element.scrollLeft;
    if (visibleWidth === totalWidth) {
      return;
    }
    if (scrollPosition === 0 && visibleWidth < totalWidth) {
      this.setState({
        leftOverflowVisible: false,
        rightOverflowVisible: true
      })
    }
    if ((visibleWidth + scrollPosition) === totalWidth) {
      this.setState({
        leftOverflowVisible: true,
        rightOverflowVisible: false
      })
    }

    if (scrollPosition > 0 && (visibleWidth + scrollPosition) < totalWidth) {
      this.setState({
        leftOverflowVisible: true,
        rightOverflowVisible: true
      })
    }
  }
  render() {
    const { t, data, descriptionRef } = this.props;
    const {
      productDescription,
      detailedParameters,
      advantages,
      disadvantages,
      ratings,
      attachments,
      additionalInfos
    } = data;
    const showRatings = !_.isEmpty(_.get(ratings, 'criteria', []));
    const additionalInfosCustom = _.filter(additionalInfos, info => (parseInt(info.type) !== PRODUCT_ADDITIONAL_INFO_TYPE_SIZE));
    const additionalInfosSize = _.filter(additionalInfos, info => (parseInt(info.type) === PRODUCT_ADDITIONAL_INFO_TYPE_SIZE));
    if (
      _.isEmpty(productDescription) &&
      _.isEmpty(detailedParameters) &&
      _.isEmpty(advantages) &&
      _.isEmpty(showRatings) &&
      _.isEmpty(attachments) &&
      _.isEmpty(additionalInfosCustom) &&
      _.isEmpty(additionalInfosSize)
    ) {
      return null;
    }

    resetIdCounter();

    return (
      <S.Tabs>
        <S.TabListWrappper
          rightOverflowVisible={this.state.rightOverflowVisible}
          leftOverflowVisible={this.state.leftOverflowVisible}
        >
          <Container>
            <Row>
              <Column size={{ xs: 12, lg: 12 }}>
                <S.TabList ref={this.tabListRef} onScroll={this.onTabListScroll}>
                  {!_.isEmpty(productDescription) ? <S.Tab>{capfl(t('description'))}</S.Tab> : ''}
                  {!_.isEmpty(detailedParameters) ? <S.Tab>{capfl(t('parameters'))}</S.Tab> : ''}
                  {(!_.isEmpty(advantages) && _.get(advantages, 'description', '')) || (!_.isEmpty(disadvantages) && _.get(disadvantages, 'description', '')) ? (
                    <S.Tab>{capfl(t('advantages/disadvantages'))}</S.Tab>
                  ) : (
                    ''
                  )}
                  {!_.isEmpty(showRatings) ? <S.Tab>{capfl(t('ratings'))}</S.Tab> : ''}
                  {!_.isEmpty(attachments) ? <S.Tab>{capfl(t('attachments'))}</S.Tab> : ''}
                  {!_.isEmpty(additionalInfosCustom) ? (
                    <S.Tab>{capfl(t('additional info'))}</S.Tab>
                  ) : (
                    ''
                  )}
                  {!_.isEmpty(additionalInfosSize) ? (
                    <S.Tab>{capfl(t('size info'))}</S.Tab>
                  ) : (
                    ''
                  )}
                </S.TabList>
              </Column>
            </Row>
          </Container>
        </S.TabListWrappper>
        <S.TabsContentWrapper>
          <Container>
            <Row>
              <Column size={{ xs: 12, lg: 12 }}>
                {!_.isEmpty(productDescription) ? (
                  <TabPanel>
                    <Description ref={descriptionRef} data={productDescription} />
                  </TabPanel>
                ) : (
                  ''
                )}
                {!_.isEmpty(detailedParameters) ? (
                  <TabPanel>
                    <Parameters data={detailedParameters} />
                  </TabPanel>
                ) : (
                  ''
                )}
                { (!_.isEmpty(advantages) && _.get(advantages, 'description', '')) || (!_.isEmpty(disadvantages) && _.get(disadvantages, 'description', '')) ? (
                  <TabPanel>
                    <Advantages data={{ advantages, disadvantages }} />
                  </TabPanel>
                ) : (
                  ''
                )}
                {!_.isEmpty(showRatings) ? (
                  <TabPanel>
                    <Ratings data={ratings} />
                  </TabPanel>
                ) : (
                  ''
                )}
                {!_.isEmpty(attachments) ? (
                  <TabPanel>
                    <Attachments t={t} data={attachments} />
                  </TabPanel>
                ) : (
                  ''
                )}
                {!_.isEmpty(additionalInfosCustom) ? (
                  <TabPanel>
                    <AdditionalInfos data={additionalInfosCustom} />
                  </TabPanel>
                ) : (
                  ''
                )}
                {!_.isEmpty(additionalInfosSize) ? (
                  <TabPanel>
                    <AdditionalInfos data={additionalInfosSize} />
                  </TabPanel>
                ) : (
                  ''
                )}
              </Column>
            </Row>
          </Container>
        </S.TabsContentWrapper>
      </S.Tabs>
    );
  }
}

export default withTranslation()(ProductTabs);
