import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { withCssUnit } from 'common/components/styleguide/utils/index';
import Carousel from 'common/components/styleguide/Carousel';

import loadable from '@loadable/component';
const ProductCard = loadable(() => import(`common/blocks/product/card`));

import ProductCardSmall from 'common/components/_partials/product/cardSmall/_default/index';

import { fireGTMEvent } from 'common/utils/gtm';
import { PRODUCT_CLICK } from 'common/constants/GTMConstants';

const S = {};

S.ProductCardWrapper = styled.div`
  ${({ theme }) =>
    `padding: ${withCssUnit(
      _.get(theme, 'gutterWidth')
        ? Math.floor(_.get(theme, 'gutterWidth') / 2)
        : 0
    )};`}
`;

const defaultCarouseSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

const StyledSliderWrapper = styled.div`
  position: relative;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    padding: 0;
  }
`;

class ProductCarousel extends PureComponent {
  onProductClick = (item, gtm, i) => e => {
    fireGTMEvent(
      PRODUCT_CLICK,
      [item],
      _.get(gtm, 'list', 'Product slider'),
      Object.assign({}, gtm, { position: i + 1 })
    );
  };
  render() {
    const {
      data,
      settings = {},
      useSmallCard = false,
      CustomNextArrow = null,
      CustomPrevArrow = null,
      CustomDots = null,
      type = 'notype',
      gtm = {}
    } = this.props;
    if (!_.isEmpty(data)) {
      const finalSettings = { ...defaultCarouseSettings, ...settings };
      return (
        <StyledSliderWrapper>
          <Carousel
            settings={finalSettings}
            CustomNextArrow={CustomNextArrow}
            CustomPrevArrow={CustomPrevArrow}
            CustomDots={CustomDots}
          >
            {_.map(data, (item, i) => {
              const itemId = _.get(item, 'id', i);
              const key = 'product-carousel-' + type + '-item-' + itemId;
              return (
                <S.ProductCardWrapper key={key}>
                  {useSmallCard ? (
                    <ProductCardSmall
                      data={item}
                      onClick={this.onProductClick(item, gtm, i)}
                    />
                  ) : (
                    <ProductCard
                      imageWidth={325}
                      imageHeight={240}
                      data={item}
                      GTMCategoryList={_.get(gtm, 'list')}
                    />
                  )}
                </S.ProductCardWrapper>
              );
            })}
          </Carousel>
        </StyledSliderWrapper>
      );
    }
    return null;
  }
}

export default ProductCarousel;
