import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import List from 'common/components/styleguide/List';
import RouteLink from 'common/components/_base/RouteLink';
import Dropdown from 'common/components/styleguide/Dropdown';
import Icon from 'common/components/styleguide/Icon';

const DEFAULT_TEXT_COLOR = '#535b6b';
const DEFAULT_LINK_COLOR = DEFAULT_TEXT_COLOR;
const DEFAULT_LINK_HOVER_COLOR = '#535b6b';

const DEFAULT_FONT_SIZE = '0.7em';
const DEFAULT_FONT_WEIGHT = 'normal';

const S = {};

S.List = styled(List)`
  && {
    padding: 0;
  }
`;

S.ListItem = styled.li`
  text-decoration: none;
  &:not(:last-child) {
    border-bottom: ${({ theme }) =>
      _.get(theme, 'breadcrumbs.dropdownItem.borderBottom', 'none')};
  }
`;

S.Link = styled(RouteLink)`
  && {
    text-decoration: none;
    font-size: ${({ theme }) =>
      _.get(theme, 'breadcrumbs.dropdownItem.fontSize', DEFAULT_FONT_SIZE)};
    font-weight: ${({ theme }) =>
      _.get(theme, 'breadcrumbs.dropdownItem.fontWeight', DEFAULT_FONT_WEIGHT)};
    color: ${({ theme }) =>
      _.get(theme, 'breadcrumbs.dropdownItem.color', DEFAULT_LINK_COLOR)};
    text-decoration: ${({ theme }) =>
      _.get(theme, 'breadcrumbs.dropdownItem.textDecoration')};
    display: block;
    padding: 10px 0;
    &:hover {
      color: ${({ theme }) =>
        _.get(theme, 'breadcrumbs.link.hover.color', DEFAULT_LINK_HOVER_COLOR)};
      text-decoration: ${({ theme }) =>
        _.get(theme, 'breadcrumbs.link.hover.textDecoration')};
    }
  }
`;

S.TextDropdownShow = styled.div`
  display: flex;
  align-items: center;
`;

S.DropdownText = styled.span`
  font-size: ${({ theme }) =>
    _.get(theme, 'breadcrumbs.text.fontSize', DEFAULT_FONT_SIZE)};
  color: ${({ theme }) =>
    _.get(theme, 'breadcrumbs.dropdown.color', DEFAULT_TEXT_COLOR)};
  font-weight: ${({ theme }) =>
    _.get(theme, 'breadcrumbs.dropdown.fontWeight', DEFAULT_FONT_WEIGHT)};
  text-decoration: ${({ theme }) =>
    _.get(theme, 'breadcrumbs.dropdown.textDecoration')};
`;

S.Text = styled.div`
  font-size: ${({ theme }) =>
    _.get(theme, 'breadcrumbs.text.fontSize', DEFAULT_FONT_SIZE)};
  color: ${({ theme }) =>
    _.get(theme, 'breadcrumbs.text.color', DEFAULT_TEXT_COLOR)};
  font-weight: ${({ theme }) =>
    _.get(theme, 'breadcrumbs.text.fontWeight', DEFAULT_FONT_WEIGHT)};
`;

S.LinkCustom = styled(RouteLink)`
  && {
    font-size: ${({ theme }) =>
      _.get(theme, 'breadcrumbs.link.fontSize', DEFAULT_FONT_SIZE)};
    color: ${({ theme }) =>
      _.get(theme, 'breadcrumbs.link.color', DEFAULT_LINK_COLOR)};
    font-weight: ${({ theme }) =>
      _.get(theme, 'breadcrumbs.link.fontWeight', DEFAULT_FONT_WEIGHT)};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Item = ({ data, withSameLevels = true }) => {
  const { name, link = {}, sameLevelCategories = [], urlName = null } = data;
  if (!_.isEmpty(sameLevelCategories)) {
    if (withSameLevels) {
      return (
        <Dropdown
          showText={
            <S.TextDropdownShow>
              <S.DropdownText>{name}</S.DropdownText>
              <Icon className="nc-ic_arrow_drop_down_18px" />
            </S.TextDropdownShow>
          }
        >
          <S.List reset>
            {_.map(sameLevelCategories, item => {
              return (
                <S.ListItem key={'breadcrumbs-samelvl-item-' + item.id}>
                  <S.Link
                    href={item.externalUrl}
                    route="category"
                    params={{ id: item.urlName }}
                  >
                    {item.name}
                  </S.Link>
                </S.ListItem>
              );
            })}
          </S.List>
        </Dropdown>
      );
    } else {
      return (
        <S.LinkCustom route="category" params={{ id: urlName }}>
          {name}
        </S.LinkCustom>
      );
    }
  }
  if (!_.isEmpty(link)) {
    return <S.LinkCustom {...link}>{name}</S.LinkCustom>;
  }
  return <S.Text>{name}</S.Text>;
};

export default Item;
