import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { capfl } from 'common/utils/index';
import { Container, Row, Column } from 'common/components/styleguide/grid';
import Image from 'common/components/styleguide/Image';
import RouteLink from 'common/components/_base/RouteLink';

import Search from 'common/blocks/functional/autocomplete';

import avocado from 'assets/images/freshbox/avocado.svg';

const S = {};
S.Wrapper = styled.section`
  margin: 0 0 60px 0;
  font-family: ${({ theme }) => _.get(theme, 'fontFamilySecondary')};
  width: 100%;
`;

S.ErrorCode = styled.strong`
  margin-top: 30px;
  font-size: 170pt;
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
  display: block;
  line-height: 170pt;
`;

S.ColumnLeft = styled(Column)`
  align-items: center;
  justify-content: center;
`;

S.ColumnRight = styled(Column)`
  align-items: center;
`;

S.Image = styled(Image)`
  width: 80%;
  margin: 50px 0;
`;

S.Title = styled.div`
  margin: 0 0 10px 0;
  color: ${({ theme }) => _.get(theme, 'colors.black', '#000')};
  font-size: 32pt;
  font-weight: bold;
  line-height: 32pt;
`;

S.SubTitle = styled.div`
  margin: 0 0 30px 0;
  color: ${({ theme }) => _.get(theme, 'colors.black', '#000')};
  font-size: 22pt;
  font-weight: normal;
  line-height: 22pt;
`;

S.SearchWrapper = styled.div`
  margin: 40px 0;
  position: relative;

  & > div {
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
  }

  section {
    width: 100%;
    right: 0;
    left: 0;
  }
`;

S.RouteLink = styled(RouteLink)`
  color: ${({ theme }) => _.get(theme, 'colors.primary', '#000')};
  text-decoration: underline;
  display: inline-flex;
  font-size: 0.95em;
`;

const Error404 = ({ t }) => {
  return (
    <S.Wrapper>
      <Container>
        <Row>
          <S.ColumnLeft size={{ xs: 12, md: 6 }} visible={{ xs: false, md: true }}>
            <S.Image src={avocado} staticImage={true} />
          </S.ColumnLeft>
          <S.ColumnRight size={{ xs: 12, md: 6 }}>
            <S.ErrorCode>404</S.ErrorCode>
            <S.Title>{capfl(t('page not found'))}</S.Title>
            <S.SubTitle>{capfl(t('try again later'))}.</S.SubTitle>

            <S.SearchWrapper>
              <Search />
            </S.SearchWrapper>

            <S.RouteLink route="home">{capfl(t('back to homepage'))} &nbsp; &rsaquo;</S.RouteLink>
          </S.ColumnRight>
        </Row>
      </Container>
    </S.Wrapper>
  );
};

export default withTranslation()(Error404);
